@import 'colors';

kn-datagrid {
	kn-reordable-handler {
		padding: 10px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		transition: 0.5s background-color ease;

		&.dragging {
			background-color: rgba($column-active-color, 0.9);
		}
	}

	thead tr.dragging kn-reordable-handler:not(.dragging) {
		transition: 0.5s left ease;
	}
}
