@import '../shared/blend';

@mixin strio-datepicker-circular-link($size) {
	width: $size - 2px;
	height: $size - 2px;
	line-height: $size - 2px;
	text-align: center;
	border-radius: 50%;
	border: 1px solid transparent;

	&, &:hover, &:active, &:focus {
		text-decoration: none;
		z-index: 1;
	}

	&:hover, &:focus {
		outline: none;
	}
}

@mixin strio-datepicker-circular-link-theme($theme) {
	color: map-get($theme, text);

	&:hover, &:focus {
		background-color: map-get($theme, focus);
	}

	&.now {
		color: map-get($theme, primary);
		border: 1px solid map-get($theme, primary);
	}

	&.selected {
		background-color: map-get($theme, primary);
		color: map-get($theme, background);
	}
}

@mixin strio-datepicker() {
	kn-datepicker {
		.viewport {
			filter: drop-shadow(0 3px 12px rgba(0, 0, 0, 0.3));

			&::before {
				content: '';
				position: absolute;
				display: block;
				left: calc(50% - 10px);
				top: -10px;
				width: 0;
				height: 0;
				border-left: 10px solid transparent;
				border-right: 10px solid transparent;
			}

			&.kn-enter, &.kn-leave {
				transition: 0.3s opacity ease, 0.3s margin-top ease, 0.3s transform ease;

				kn-date-view, kn-day-view, kn-hour-view {
					opacity: 1 !important;
					transform: none !important;
				}
			}

			&.kn-enter {
				opacity: 0;
				margin-top: 10px;
			}

			&.kn-leave, &.kn-enter.kn-enter-active {
				opacity: 1;
				margin-top: 0;
			}

			&.kn-leave.kn-leave-active {
				opacity: 0;
				margin-top: 10px;
			}
		}

		kn-date-header, kn-time-header {
			a, a:hover {
				text-decoration: none;
			}
		}

		kn-date-header a {
			text-align: center;
			font-weight: 900;
		}

		kn-time-header a {
			font-weight: 400;
			border-radius: 10%;
		}

		kn-date-view, kn-day-view, kn-hour-view {
			&.kn-enter, &.kn-leave {
				transition: 0.3s opacity ease, 0.3s transform ease;
			}

			&.kn-enter {
				opacity: 0;
				transform: scale(0.7, 0.7);
			}

			&.kn-leave, &.kn-enter.kn-enter-active {
				opacity: 1;
				transform: scale(1, 1);
			}

			&.kn-leave.kn-leave-active {
				opacity: 0;
				transform: scale(1.4, 1.4);
			}
		}

		kn-month-view {
			&.animated {
				transition: 0.3s transform ease;
			}

			th, td {
				text-align: center;
				border: 1px solid transparent;
			}

			th {
				font-size: 14px;
				font-weight: 400;
				text-transform: uppercase;
			}

			td a {
				@include strio-datepicker-circular-link(44px);
			}
		}

		.picker-relative-container {
			position: relative;
			width: calc(100% - 44px);
			height: calc(100% - 44px);
			margin: 22px;

			&.format-12-hours {
				width: calc(100% - 80px);
				height: calc(100% - 80px);
				margin: 40px;
			}

			.center {
				position: absolute;
				left: calc(50% - 5px);
				top: calc(50% - 5px);
				width: 10px;
				height: 10px;
				z-index: 1;
				border-radius: 50%;
			}

			.gauge {
				position: absolute;
				left: 50%;
				top: 50%;
				height: 1px;
				transform-origin: 0 0;
			}

			li {
				text-align: center;
				border: 1px solid transparent;

				&::before {
					border-radius: 50%;
				}

				&.minor {
					a {
						@include strio-datepicker-circular-link(15px);
						font-size: 0;
					}
				}

				&:not(.minor) {
					a {
						@include strio-datepicker-circular-link(44px);
					}
				}
			}
		}

		.period-selector a {
			@include strio-datepicker-circular-link(44px);
		}
	}
}

@mixin strio-datepicker-theme($theme) {
	kn-datepicker {
		.viewport {
			background-color: map-get($theme, background);

			&::before {
				border-bottom: 10px solid blend(map-get($theme, background), map-get($theme, tone));
			}

			.header {
				background-color: blend(map-get($theme, background), map-get($theme, tone));
			}
		}

		kn-date-header, kn-time-header {
			& > * {
				color: map-get($theme, foreground);
			}

			a:hover {
				background-color: map-get($theme, button-hover);
			}

			a:active {
				background-color: map-get($theme, button-active);
			}
		}

		kn-date-header a {
			color: map-get($theme, text-secondary);
		}

		kn-month-view {
			th {
				color: map-get($theme, placeholder);
			}

			td a {
				@include strio-datepicker-circular-link-theme($theme);
			}
		}

		.picker-relative-container {
			.center {
				background-color: blend(map-get($theme, background), map-get($theme, icon-secondary));
			}

			.gauge {
				background-color: map-get($theme, divider);
			}

			li {
				&::before {
					background-color: map-get($theme, background);
				}

				&.minor {
					a {
						@include strio-datepicker-circular-link-theme($theme);
					}

					&::before {
						background-color: map-get($theme, icon);
					}
				}

				&:not(.minor) a {
					@include strio-datepicker-circular-link-theme($theme);
				}

				&.major::before {
					background-color: map-get($theme, foreground);
				}
			}
		}

		.period-selector a {
			@include strio-datepicker-circular-link-theme($theme);
		}
	}
}
