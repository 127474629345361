/* stylelint-disable no-descending-specificity */

.CodeMirror-gutters {
	background-color: #e8e8ec;
	border-right: solid 1px #e0e0e8;
	height: 100%;
	box-shadow: 0 0 5px #e0e0e8;
}

.CodeMirror-foldgutter {
	width: 1em !important;
}

.CodeMirror-linenumbers {
	padding-right: 5px;
	width: 3.7em !important;
}

.CodeMirror-linenumber {
	color: #b9b6d2;
	font-size: 1em;
	padding: 0 5px 0 0;
	text-shadow: 0 0 1px #ffffff;
}

.CodeMirror-foldgutter-open,
.CodeMirror-foldgutter-folded {
	position: relative;
	font-family: monospace;
	font-size: 24px;
	height: 1em;
}

.CodeMirror-foldgutter-folded {
	line-height: 1.7em;
}

.CodeMirror-foldgutter-open::after {
	content: '\25BE' !important;
	position: absolute;
	top: -2px;
	color: #b9b6d2;
}

.CodeMirror-activeline .CodeMirror-foldgutter-open::after {
	color: #787a82 !important;
}

.CodeMirror-foldgutter-folded::after {
	content: '\25BE' !important;
	position: absolute;
	top: -8px;
	color: #4a4543;
	transform: rotate(270deg);
}

.CodeMirror.over-gutter .CodeMirror-foldgutter-open::after,
.CodeMirror-activeline .CodeMirror-foldgutter-open::after {
	color: #b9b6d2;
}

.CodeMirror-foldmarker {
	position: relative;
	background-color: #00bcd4;
	color: transparent !important;
	padding: 0 0.5em;
	text-shadow: none !important;
	margin: 0 0.5em;
	border-radius: 3px;
}

.CodeMirror-foldmarker::after {
	position: absolute;
	content: '↔';
	color: white;
	top: 0.4em;
	left: 0.5em;
	font-family: 'arial';
}

.CodeMirror-scroll {
	color: #4a4543;
	line-height: 1.55em;
}

.CodeMirror-cursor {
	border-left: 1px solid #39464e;
	height: 1em;
}

.CodeMirror-selected {
	background-color: #d7d4f0;
	border: 0;
	outline: none;
}

.CodeMirror-activeline-background {
	background: rgba(216, 144, 56, 0.1);
}

.CodeMirror-focused .cm-matchhighlight {
	border-width: 0 !important;
}

.cm-em {
	font-style: italic;
}

.cm-link {
	text-decoration: underline;
}

.cm-strikethrough {
	text-decoration: line-through;
}

.cm-header {
	color: #4695ee;
}

.cm-positive,
.cm-quote {
	color: #249d82;
}

.cm-negative {
	color: #ff4d4d;
}

.cm-keyword {
	color: #a151d2;
}

.cm-comment {
	color: #a0a1a7;
}

.cm-property {
	color: #39464e;
}

.cm-string {
	color: #249d82;
}

.cm-number,
.cm-string-2 {
	color: #ed5c65;
}

.cm-tag.cm-bracket {
	color: #000000;
}

.cm-atom {
	color: #221199;
}

.cm-keyword,
.cm-quote {
	color: #a151d2;
}

.cm-attribute,
.cm-builtin,
.cm-def,
.cm-qualifier,
.cm-keyword + span[class^='cm-m-javascript'] + .cm-variable,
.cm-dk-whitespace-leading-space + .cm-variable,
.cm-m-javascript + .cm-variable {
	color: #f18c16;
}

.cm-tag,
.cm-variable,
.cm-variable-2,
.cm-variable-3,
.cm-operator + span[class^='cm-m-javascript'] + .cm-variable {
	color: #4695ee;
}

.cm-m-xml.cm-tag,
.cm-m-xml.cm-keyword {
	color: #000000;
	text-shadow: 0 0 1px #ffffff;
}

.cm-m-xml.cm-tag ~ .cm-m-xml.cm-variable {
	color: #ed5c65;
	font-style: italic;
	font-weight: bold;
	text-shadow: 0 0 1px #ffffff;
}

.cm-m-xml.cm-bracket + .cm-m-xml.cm-tag {
	color: #4695ee;
	font-weight: normal;
}

.cm-m-xml.cm-bracket {
	color: #4a4543;
	font-weight: normal;
}

.cm-m-xml.cm-tag {
	color: #4a4543;
}

.cm-m-xml.cm-keyword {
	color: #000000;
	font-weight: bold;
}

.cm-atom,
.cm-attribute,
.cm-builtin,
.cm-comment,
.cm-def,
.cm-keyword,
.cm-number,
.cm-property,
.cm-qualifier,
.cm-quote,
.cm-tag,
.cm-variable,
.cm-variable-2,
.cm-variable-3,
.cm-string,
.cm-string-2 {
	text-shadow: 0 1px 1px #ffffff;
}

.CodeMirror-focused .CodeMirror-activeline .CodeMirror-gutter-elt {
	color: #4a4543;
	text-shadow: 0 0 1px #ffffff;
}

.CodeMirror-nonmatchingbracket,
.CodeMirror-matchingbracket {
	background: rgba(255, 150, 0, 0.3);
	color: #000000 !important;
	padding: 3px 0;
}

.cm-m-xml.CodeMirror-matchingtag {
	background: transparent;
}
