@mixin strio-tabs-notebook() {
	kn-tabs.notebook div.tabs-labels li {
		a {
			padding: 0 30px;
			height: 50px;
			line-height: 50px;
			transition: 0.3s color ease;

			&:hover, &:focus {
				text-decoration: none;
				outline: none;
			}

			&::before {
				content: '';
				display: block;
				position: absolute;
				width: 80%;
				height: 4px;
				left: 10%;
				top: -10px;
				opacity: 0;
				z-index: 10;
				transform: scale(0.8);
				transition: 0.3s all ease;
			}

			&:hover::before, &:focus::before {
				opacity: 1;
				top: 0;
				transform: scale(1);
			}
		}

		&.active a {
			&::before {
				opacity: 1;
				top: 0;
				transform: scale(1);
				width: 100%;
				left: 0;
			}
		}
	}
}

@mixin strio-tabs-notebook-theme($theme) {
	kn-tabs.notebook div.tabs-labels {
		background-color: map-get($theme, panel);

		li {
			a {
				color: map-get($theme, primary);

				&:hover, &:focus {
					color: map-get($theme, foreground);
				}

				&::before {
					background-color: map-get($theme, line);
				}
			}

			&.active a {
				background-color: rgba(map-get($theme, background), 0.5); // sass-lint:disable-line no-color-literals

				&::before {
					background-color: map-get($theme, primary);
				}
			}
		}
	}
}
