@mixin strio-loading-screen() {
	.app-loading-screen {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;

		p {
			padding: 5px 10px;
			margin: 0;
			border-radius: 3px;
		}
	}
}

@mixin strio-loading-screen-theme($theme) {
	.app-loading-screen p {
		color: map-get($theme, background);
		background-color: rgba(map-get($theme, foreground), 0.2);
	}
}
