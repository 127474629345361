// sass-lint:disable no-color-literals

kn-datagrid {
	tr td {
		&.strong {
			font-weight: bold;
		}

		&.highlight {
			color: rgb(128, 32, 32);
		}

		&.note {
			color: rgb(128, 128, 128);
			font-size: 0.8em;
		}

		&.monospace {
			font-family: monospace;
		}

		&.overflow {
			overflow: visible;
		}
	}

	kn-cell-renderer {
		span.color {
			display: inline-block;
			width: 24px;
			height: 20px;
			margin-right: 4px;
			box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
		}

		span.user-database-tag, span.user-role-tag {
			color: white;
			padding: 4px 7px;
			margin: 0 5px 0 0;
			border-radius: 2px;
		}

		span.user-ownership {
			color: rgba(0, 0, 0, 0.3);
			& > span {
				color: rgba(0, 0, 0, 1);
				background-color: rgba(0, 0, 0, 0.1);
				padding: 4px;
				margin: 0 4px;
				border-radius: 2px;
			}
		}
	}

	tr {
		&.worn td {
			background-color: rgba(224, 144, 32, 0.1);
		}
	}
}
