@import '../shared/field';
@import '../shared/native-field';

@mixin strio-dateinput() {
	@include strio-interpolated-field(date-input);

	kn-date-input input {
		min-height: 24px;
		text-align: right;
	}

	kn-date-input .input-button {
		i.material-icons {
			opacity: 0.5;
			transition: 0.3s opacity ease;

			&:hover, &:focus {
				opacity: 1;
			}
		}

		&.disabled, &.readonly {
			i.material-icons {
				opacity: 0.5;
			}
		}

		& > a {
			display: flex;
			text-decoration: none;
		}
	}

	// HACK: workaround for firefox clear button
	// sass-lint:disable no-vendor-prefixes
	@-moz-document url-prefix() {
		kn-date-input input {
			clip-path: inset(0 25px 0 0);
			margin-right: -20px !important;
			min-width: 70px !important;
		}
	}
}

@mixin strio-dateinput-theme($theme) {
	@include strio-interpolated-field-theme(date-input, $theme);
	@include strio-interpolated-native-field-theme(date-input, $theme);

	kn-date-input .input-button {
		i.material-icons {
			color: map-get($theme, icon);
		}

		&.disabled, &.readonly {
			i.material-icons {
				color: map-get($theme, icon-disabled);
			}
		}
	}
}
