@mixin strio-directives-ripple() {
	.kn-ripple {
		overflow: hidden;
		position: relative;
	}

	.kn-ripple.kn-ripple-unbounded {
		overflow: visible;
	}

	.kn-ripple-element {
		position: absolute;
		border-radius: 50%;
		pointer-events: none;
		transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
		transform: scale(0);
	}
}

@mixin strio-directives-ripple-theme($theme) {
	.kn-ripple-element {
		background-color: rgba(map-get($theme, foreground), 0.05);
	}
}
