@import '../shared/grid-menu-button';

@mixin strio-grid-reset() {
	kn-grid-reset {
		@include strio-grid-menu-button();

		padding: 0;

		& > *:not(.disabled) {
			height: 24px;
			padding: 5px;
			transition: color 0.5s ease;

			&:hover, &:focus {
				opacity: 1;
			}
		}

		& > span {
			display: flex;
		}

		& > a {
			opacity: 0.7;
			display: inline-block;
			line-height: 24px;
			height: 24px;
			padding: 5px;

			&.disabled {
				cursor: default;
				pointer-events: none;
			}
		}
	}
}

@mixin strio-grid-reset-theme($theme) {
	kn-grid-reset {
		@include strio-grid-menu-button-theme($theme);
		color: rgba(map-get($theme, background), 0.6); // sass-lint:disable-line no-color-literals

		& > *:not(.disabled), a:not(.disabled) {
			color: rgba(map-get($theme, background), 0.6); // sass-lint:disable-line no-color-literals
			transition: color 0.5s ease;

			&:hover, &:focus {
				color: map-get($theme, background);
				background-color: map-get($theme, grid-menu-activated-hover);
			}
		}

		& > *.disabled, a.disabled {
			color: rgba(map-get($theme, background), 0.3); // sass-lint:disable-line no-color-literals
		}
	}
}
