@mixin strio-grid-menu() {
	$grid-menu-shadow-color: rgba(0, 0, 0, 0.2);

	display: block;
	position: relative;

	&.expanded {
		box-shadow: 0 2px 5px $grid-menu-shadow-color;
	}

	kn-menu-activator {
		display: flex;
		align-items: center;
		outline: none;
		margin: 0;
		border-radius: 2px;
		opacity: 0.7;
		transition: background-color 0.5s ease;
		cursor: pointer;

		&[hidden] {
			display: none;
		}

		&:hover, &:focus {
			&, i {
				opacity: 1;
			}
		}

		&:hover, &:focus, &:active {
			text-decoration: none;
		}

		i {
			width: 24px;
			height: 24px;

			&:first-child {
				margin-right: 0.1em;
			}
		}
	}

	kn-menu-content:not(.kn-menu-content) {
		min-width: 100%;
		position: absolute;
		z-index: 2;
		box-shadow: 0 2px 5px $grid-menu-shadow-color;

		&.empty {
			display: none;
		}
	}

	@at-root kn-attached-portal .kn-grid-menu-content {
		min-width: 100%;
		position: absolute;
		z-index: 2;
		box-shadow: 0 2px 5px $grid-menu-shadow-color;

		&.empty {
			display: none;
		}
	}
}

@mixin strio-grid-menu-theme($theme) {
	&.expanded {
		background-color: map-get($theme, grid-menu-background);
	}

	&:not(.expanded) kn-menu-activator:focus, kn-menu-activator:hover {
		background-color: map-get($theme, grid-menu-hover);
	}

	kn-menu-activator {
		&, i {
			color: rgba(map-get($theme, background), 0.8); // sass-lint:disable-line no-color-literals
		}

		&:hover, &:hover i {
			color: map-get($theme, background);
		}
	}

	kn-menu-content:not(.kn-menu-content) {
		background-color: map-get($theme, grid-menu-background);
		border-top: 1px solid map-get($theme, grid-divider-background);
	}

	@at-root kn-attached-portal .kn-grid-menu-content {
		background-color: map-get($theme, grid-menu-background);
		border-top: 1px solid map-get($theme, grid-divider-background);
	}

	@at-root kn-attached-portal .kn-grid-button-content {
		background-color: map-get($theme, grid-menu-background);
		border-top: 1px solid map-get($theme, grid-divider-background);
	}
}
