@import 'blend';
@import 'colors';

kn-datagrid tbody[knTableBody] {
	& > tr {
		& > td {
			position: relative;
			border-bottom: 1px solid $row-divider-color;
			padding: 10px;
			overflow: hidden;
			text-overflow: ellipsis;

			&.group {
				padding-left: 40px;
			}
		}

		&.selected {
			background-color: $row-selected-color;
		}

		&.dragging {
			box-shadow: 0 0 15px $row-dragging-shadow-color;
			background-color: $row-selected-color;

			& > td:first-child {
				position: relative;

				&::before {
					content: '';
					position: absolute;
					display: block;
					background-color: $primary-color;
					width: 5px;
					height: 100%;
					top: 0;
					left: 0;
				}
			}
		}

		&:hover {
			background-color: $row-hover-color;
		}

		&:not(.leaf) {
			font-weight: bold;
			background-color: $row-child-color;

			&:hover {
				background-color: blend($row-child-color, $row-hover-color);
			}
		}
	}

	&:focus {
		outline: none;

		& > tr.marked > td:first-child {
			position: relative;

			&::before {
				content: '';
				position: absolute;
				display: block;
				background-color: $primary-color;
				width: 5px;
				height: 100%;
				top: 0;
				left: 0;
			}
		}
	}
}
