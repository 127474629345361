@import '../shared/grid-menu-button';

@mixin strio-print-button() {
	kn-print-button kn-menu {
		@include strio-grid-menu-button();

		kn-menu-content {
			min-width: 200px;
		}

		kn-spinner {
			left: 14px;
		}
	}

	@at-root kn-attached-portal .kn-menu-content.kn-print-button-content {
		min-width: 200px;
	}
}

@mixin strio-print-button-theme($theme) {
	kn-print-button kn-menu {
		@include strio-grid-menu-button-theme($theme);
	}
}
