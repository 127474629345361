@import '../shared/field';
@import '../shared/native-field';

@mixin strio-textarea() {
	@include strio-interpolated-field(textarea);

	kn-textarea {
		height: 100%;
		display: flex !important;
		flex-direction: column;

		.kn-textarea-container {
			flex: 1;
		}

		textarea {
			height: calc(100% - 20px);
		}

		&:not(.resizable) textarea {
			resize: none;
		}

		&.resizable-x textarea {
			resize: horizontal;
		}

		&.resizable-y textarea {
			resize: vertical;
		}
	}
}

@mixin strio-textarea-theme($theme) {
	@include strio-interpolated-field-theme(textarea, $theme);
	@include strio-interpolated-native-field-theme(textarea, $theme);
}
