@import '../shared/grid-menu';

@mixin strio-grid-menu-popup() {
	@include strio-grid-menu();

	$grid-menu-popup-shadow-color: rgba(0, 0, 0, 0.3);

	kn-menu-activator {
		padding: 5px 7px;
	}

	kn-menu-content:not(.kn-menu-content) {
		position: absolute;
		z-index: 20;
		left: 50%;
		top: 50px;
		filter: drop-shadow(0 3px 12px $grid-menu-popup-shadow-color);

		&::before {
			position: absolute;
			left: calc(50% - 10px);
			top: -10px;
			content: '';
			display: block;
			width: 0;
			height: 0;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
		}
	}

	@at-root kn-attached-portal .kn-grid-menu-content {
		position: absolute;
		z-index: 20;
		transform: translateY(10px);
		filter: drop-shadow(0 3px 12px $grid-menu-popup-shadow-color);

		&::before {
			position: absolute;
			left: calc(100% - 100px);
			top: -10px;
			content: '';
			display: block;
			width: 0;
			height: 0;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
		}
	}
}

@mixin strio-grid-menu-popup-theme($theme) {
	@include strio-grid-menu-theme($theme);

	kn-menu-content:not(.kn-menu-content) {
		background-color: map-get($theme, background);

		&::before {
			border-bottom: 10px solid map-get($theme, background);
		}
	}

	@at-root kn-attached-portal .kn-grid-menu-content {
		background-color: map-get($theme, background);

		&::before {
			border-bottom: 10px solid map-get($theme, background);
		}
	}
}
