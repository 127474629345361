@mixin strio-form-errors() {
	$form-errors-color: white;

	.form-errors {
		margin: 0;
		padding: 0;
		list-style-type: none;

		li {
			margin: 2px 0;
			padding: 0;
			display: flex;
			align-items: center;

			strong {
				display: flex;
				align-items: center;
				color: $form-errors-color;
				padding: 0.3em 0.5em;
				margin-right: 1em;

				i {
					padding-right: 0.3em;
				}
			}
		}
	}
}

@mixin strio-form-errors-theme($theme) {
	.form-errors {
		li {
			background-color: rgba(map-get($theme, warn), 0.2); // sass-lint:disable-line no-color-literals

			strong {
				background-color: map-get($theme, warn);
			}
		}
	}
}
