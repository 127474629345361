@mixin strio-control-messages() {
	kn-control-messages {
		position: relative;
		height: 15px;

		& > div {
			position: absolute;
			display: block;

			&.kn-enter, &.kn-leave {
				transition: 0.3s all ease;
			}

			&.kn-enter, &.kn-leave.kn-leave-active {
				top: -5px;
				opacity: 0;
			}

			&.kn-leave, &.kn-enter.kn-enter-active {
				top: 0;
				opacity: 1;
			}
		}
	}
}

@mixin strio-control-messages-theme($theme) {
	kn-control-messages > div {
		color: map-get($theme, warn);
	}
}
