@mixin strio-checkbox() {
	$checkbox-mark-path-color: white;

	kn-checkbox {
		.kn-checkbox-mark {
			opacity: 0;
		}

		input[type='checkbox'] {
			& + .kn-checkbox-box {
				&::before {
					border-radius: 50%;
				}

				&::after {
					border-radius: 2px;
					transition: 0.3s all ease;
				}
			}

			&:checked + .kn-checkbox-box {
				&::after {
					border-color: transparent;
				}

				.kn-checkbox-mark-path {
					stroke-dashoffset: 0;
					stroke-dasharray: 25;
				}

				.kn-checkbox-mark {
					opacity: 1;
				}
			}
		}

		.kn-checkbox-mark-path {
			stroke-dashoffset: 25;
			stroke-dasharray: 25;
			stroke-width: 2px;
			transition: 0.3s transform ease, 0.3s stroke-dashoffset ease;
			transform-origin: 50% 50%;
			stroke: $checkbox-mark-path-color;
		}

		.kn-checkbox-box::after, label {
			transition: 0.3s color ease;
		}

		&.indeterminate {
			input[type='checkbox'] + .kn-checkbox-box::after {
				border-color: transparent;
			}

			.kn-checkbox-mark {
				opacity: 1;
			}

			.kn-checkbox-mark-path {
				transform: rotate(45deg) translate(-2px, 0);
				stroke-dashoffset: 20 !important;
				stroke-dasharray: 14 !important;
			}
		}
	}
}

@mixin strio-checkbox-theme($theme) {
	kn-checkbox {
		input[type='checkbox'] {
			& + .kn-checkbox-box::after {
				border: 2px solid map-get($theme, input-divider);
				background-color: map-get($theme, undertone);
			}

			&:checked + .kn-checkbox-box::after {
				background-color: map-get($theme, primary);
			}

			&:focus + .kn-checkbox-box::before {
				background-color: map-get($theme, focus);
			}
		}

		&:not(.disabled):not(.indeterminate) input[type='checkbox']:not(:checked) {
			&:hover + .kn-checkbox-box::after, & + .kn-checkbox-box:hover::after {
				border-color: map-get($theme, input-divider-hover);
			}
		}

		&.disabled {
			input[type='checkbox']:checked + .kn-checkbox-box::after {
				background-color: map-get($theme, line-disabled);
			}

			input[type='checkbox']:not(:checked) + .kn-checkbox-box::after {
				background-color: transparent !important;
			}

			&.indeterminate .kn-checkbox-box::after {
				background-color: map-get($theme, line-disabled);
			}
		}

		&.disabled label, &.readonly label {
			color: map-get($theme, text-disabled);
		}

		&.indeterminate input[type='checkbox'] + .kn-checkbox-box::after {
			background-color: map-get($theme, primary);
		}

		&.ng-touched.ng-invalid {
			.kn-checkbox-box::after {
				border-color: map-get($theme, warn);
			}

			input[type='checkbox']:checked + .kn-checkbox-box::after, &.indeterminate .kn-checkbox-box::after {
				background-color: map-get($theme, warn);
			}
		}
	}
}
