@import '../shared/field';

@mixin strio-select() {
	@include strio-interpolated-field(select);

	kn-select {
		.kn-select-header {
			line-height: 24px;

			& > * {
				min-height: 24px;
			}

			.separator {
				padding-right: 0.3em;
			}

			.option {
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}

		&.disabled .kn-select-header {
			pointer-events: none;
		}
	}
}

@mixin strio-select-theme($theme) {
	@include strio-interpolated-field-theme(select, $theme);

	kn-select .placeholder {
		color: map-get($theme, placeholder);
	}

	.kn-select-dropdown {
		border-left: 1px solid map-get($theme, tone);
	}
}
