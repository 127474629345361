@mixin strio-flat-button() {
	display: inline-block;
	margin: 0;
	padding: 5px;
	opacity: 0.7;
	border: none;
	border-radius: 2px;
	outline: none;
	background: none;
	cursor: pointer;

	i {
		vertical-align: top;
		transition: all 0.5s ease;
		display: inline-block;
		width: 24px;
		height: 24px;
		overflow: hidden;
	}

	&:hover, &:focus {
		opacity: 1;
		text-decoration: none;
	}

	&:active {
		opacity: 1;
	}

	&[disabled] {
		opacity: 0.3;
		background: none;
	}
}

@mixin strio-flat-button-theme($theme) {
	color: map-get($theme, foreground);

	&:not([disabled]):hover, &:not([disabled]):focus {
		background-color: map-get($theme, button-hover);
	}

	&:not([disabled]):active {
		background-color: map-get($theme, button-active);
	}
}
