@import 'colors';
@import 'column-context-menu';
@import 'numbered-selector';
@import 'reordable-handler';
@import 'resizable-handler';
@import 'table-body';
@import 'table-head';
@import 'tree-bar';

kn-datagrid {
	td.group {
		background-color: $column-group-background-color;
	}

	th.left, td.left {
		text-align: left;
	}

	th.center, td.center {
		text-align: center;
	}

	th.right, td.right {
		text-align: right;
	}

	td.number {
		color: $column-number-color;
	}

	td.italic {
		font-style: italic;
	}

	td.highlight {
		color: $column-highlight-color;
	}

	tr.loader-bar th {
		height: 1px;
		background-color: $header-divider-color;
		border: none;
		padding: 0;
		transition: 0.2s all ease;
	}

	&.loading {
		&::before {
			content: '';
			position: absolute;
			background-color: $header-loading-overlay-color;
			width: 100%;
			height: 100%;
			z-index: 2;
		}

		tr.loader-bar th {
			height: 5px;
			background-color: rgba($primary-color, 0.3);

			&::after {
				content: '';
				position: absolute;
				background-color: $primary-color;
				top: 0;
				left: 0%;
				right: 100%;
				height: 100%;
				animation: indeterminate 1s linear infinite;
				z-index: 3;
			}
		}
	}
}
