@import '../shared/flat-button';

@mixin strio-common-users() {
	kn-user-edit {
		table.user-roles {
			width: 100%;
			border-collapse: collapse;
			margin: 13px 0 10px 0;

			tbody tr {
				height: 40px;
			}

			tbody td {
				transition: 0.3s background-color ease;
				padding: 10px 0 10px 10px;
			}

			td.actions {
				text-align: right;
			}

			thead, td.user-role input {
				font-weight: bold;
			}

			thead {
				text-align: center;

				tr:first-child {
					text-align: center;
				}

				tr:last-child {
					height: auto;

					td {
						font-weight: 400;
						font-style: italic;
						opacity: 0.7;
						padding-bottom: 5px;

						& > div {
							display: flex;
							& > span {
								flex: 1;
							}
						}
					}
				}
			}

			td.user-role {
				width: 200px;
			}

			td.user-role-customer-branch {
				width: 250px;
				& > div {
					display: flex;
					input {
						text-align: right;
						width: 100%;
					}
				}
			}

			td.actions {
				button {
					@include strio-flat-button();
					margin: 0 10px;
					height: 34px;
				}

				button:first-child {
					margin-left: 0;
				}

				button:last-child {
					margin-right: 0;
				}
			}
		}
	}
}

@mixin strio-common-users-theme($theme) {
	kn-user-edit {
		table.user-roles {
			tbody.user-roles-table tr:hover {
				background-color: map-get($theme, hover);
			}

			tr.invalid td {
				background-color: rgba(map-get($theme, warn), 0.15); // sass-lint:disable-line no-color-literals
			}

			thead {
				tr:last-child {
					td {
						border-bottom: 3px solid map-get($theme, line-disabled);
					}
				}
			}

			tbody.user-roles-table tr td {
				border-bottom: 1px solid map-get($theme, hover);
			}

			td.actions button {
				@include strio-flat-button-theme($theme);
			}
		}
	}
}
