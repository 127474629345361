@mixin strio-raised-button() {
	$raised-button-shadow-color: rgba(0, 0, 0, 0.2);
	$raised-button-hover-shadow-color: rgba(0, 0, 0, 0.3);

	position: relative;
	width: 100%;
	height: 45px;
	border: none;
	outline: none;
	padding: 0 30px;
	font-size: 16px;
	overflow: hidden;
	box-shadow: 0 2px 2px $raised-button-shadow-color;
	border-radius: 2px;
	transition: all 0.2s;

	&[disabled] {
		box-shadow: none;
	}

	// sass-lint:disable no-vendor-prefixes
	&::-moz-focus-inner {
		border: none;
	}
	// sass-lint:enable no-vendor-prefixes

	&::after {
		content: '';
		position: absolute;
		pointer-events: none;
		left: 50%;
		bottom: -25%;
		width: 0;
		height: 0;
		opacity: 0;
		border-radius: 50%;
		transition: 0.2s all ease;
	}

	&:not([disabled]) {
		&:hover, &:focus {
			box-shadow: 0 2px 20px $raised-button-hover-shadow-color;
		}

		&:active {
			transition: box-shadow 0.2s;
			transform: scale(0.98);
			box-shadow: 0 2px 10px $raised-button-hover-shadow-color;

			&::after {
				bottom: -70%;
				left: -50%;
				height: 200%;
				width: 200%;
				opacity: 0.3;
			}
		}
	}
}

@mixin strio-raised-button-theme($theme) {
	$button-color: white;
	$button-highlight-color: white;

	color: $button-color;
	background-color: rgba(map-get($theme, primary), 0.9); // sass-lint:disable-line no-color-literals

	&.danger {
		background-color: rgba(map-get($theme, warn), 0.9); // sass-lint:disable-line no-color-literals
	}

	&[disabled] {
		background-color: rgba(map-get($theme, disabled), 0.5); // sass-lint:disable-line no-color-literals
	}

	&::after {
		background-color: $button-highlight-color;
	}

	&:not([disabled]) {
		&:hover, &:focus {
			background-color: rgba(map-get($theme, primary), 0.8); // sass-lint:disable-line no-color-literals

			&.danger {
				background-color: rgba(map-get($theme, warn), 0.8); // sass-lint:disable-line no-color-literals
			}
		}

		&:active {
			background-color: rgba(map-get($theme, primary), 0.9); // sass-lint:disable-line no-color-literals

			&.danger {
				background-color: rgba(map-get($theme, warn), 0.9); // sass-lint:disable-line no-color-literals
			}
		}
	}
}
