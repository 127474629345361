@import 'colors';

kn-datagrid, kn-attached-portal {
	.kn-column-context-menu-viewport {
		background-color: $background-color;
		border-radius: 5px;
		filter: drop-shadow(2px 2px 7px $shadow-color);

		&::before {
			position: absolute;
			left: calc(50% - 10px);
			top: -10px;
			content: '';
			display: block;
			width: 0;
			height: 0;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			border-bottom: 10px solid $background-color;
		}

		& > kn-column-context-menu-node {
			display: block;
			overflow-y: auto;
			max-height: 350px;
		}

		ul {
			list-style-type: none;
			border-bottom: 1px solid $divider-color;

			&.disabled li, li.disabled {
				opacity: 0.4;
				a:hover {
					background-color: transparent;
				}
			}

			li {
				a {
					padding: 7px 25px 7px 30px;
					text-align: left;
					white-space: nowrap;
					color: $text-color;
					margin: 3px;
					border-radius: 5px;
					&:hover {
						background-color: $hover-color;
						text-decoration: none;
					}
				}

				a.expander {
					width: 20px;
					padding: 7px;

					&::after {
						position: absolute;
						content: '⮞';
						left: 0;
						margin-left: 10px;
					}
					&.expanded::after {
						transform: rotate(90deg);
					}
				}

				.subtree {
					flex: 1;
					& > a {
						font-weight: bold;
					}
				}
			}

			.radio.active > a::after {
				position: absolute;
				content: '\25CF';
				left: 0;
				margin-left: 10px;
				font-weight: 400;
			}

			.checkbox.active > a::after {
				position: absolute;
				content: '\2714';
				left: 0;
				margin-left: 10px;
				font-weight: 400;
			}
		}
	}

	kn-column-context-menu-node ul {
		border-bottom: none;
	}
}


.top-left .kn-column-context-menu-viewport {
	&::before {
		left: 10px;
	}
}

.top-right .kn-column-context-menu-viewport {
	&::before {
		left: calc(100% - 30px);
	}
}

.left-top .kn-column-context-menu-viewport {
	&::before {
		left: -10px;
		top: 10px;
		border-left: none;
		border-bottom: 10px solid transparent;
		border-top: 10px solid transparent;
		border-right: 10px solid $background-color;
	}
}

.right-top .kn-column-context-menu-viewport {
	&::before {
		left: calc(100%);
		top: 10px;
		border-bottom: 10px solid transparent;
		border-top: 10px solid transparent;
		border-left: 10px solid $background-color;
		border-right: none;
	}
}

.clipped .kn-column-context-menu-viewport {
	&::before {
		border-bottom: none;
		border-top: none;
		border-left: none;
		border-right: none;
	}
}
