@mixin strio-tabs-boxlist() {
	$tabs-shadow-color: rgba(0, 0, 0, 0.1);

	kn-tabs.boxlist {
		div.tabs-viewport {
			margin-top: 10px;
			box-shadow: 0 1px 7px $tabs-shadow-color;
		}

		div.tabs-labels {
			ul {
				width: 100%;
			}

			li {
				a {
					position: relative;
					flex-direction: column;
					justify-content: center;
					padding: 0;
					width: 170px;
					height: 150px !important;
					transition: 0.3s color ease;

					&:hover, &:focus {
						text-decoration: none;
						outline: none;
					}
				}

				&.active {
					a {
						border-radius: 5px;

						&::after {
							position: absolute;
							left: calc(50% - 15px);
							bottom: -10px;
							content: '';
							display: block;
							width: 0;
							height: 0;
							border-left: 15px solid transparent;
							border-right: 15px solid transparent;
						}
					}
				}
			}
		}
	}
}

@mixin strio-tabs-boxlist-theme($theme) {
	kn-tabs.boxlist {
		div.tabs-viewport {
			background-color: map-get($theme, background);
		}

		div.tabs-labels {
			li {
				a {
					color: map-get($theme, text-secondary);

					&:hover, &:focus {
						color: map-get($theme, text);
					}
				}

				&.active {
					a {
						background-color: map-get($theme, em);
						color: map-get($theme, text);

						&::after {
							border-bottom: 15px solid map-get($theme, background);
						}
					}
				}
			}
		}
	}
}
