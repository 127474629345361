@import '../shared/grid-menu-popup';

@mixin strio-grid-query-filter() {
	kn-grid-query-filter kn-menu {
		@include strio-grid-menu-popup();

		kn-menu-activator {
			padding: 0;
			border-radius: 2px;

			& > * {
				height: 24px;
				padding: 5px;
				transition: color 0.5s ease;

				&:hover, &:focus {
					opacity: 1;
				}
			}

			& > span {
				display: flex;
			}

			& > a {
				opacity: 0.7;
			}
		}
	}
}

@mixin strio-grid-query-filter-theme($theme) {
	$grid-menu-shadow-color: rgba(0, 0, 0, 0.2);

	kn-grid-query-filter kn-menu {
		@include strio-grid-menu-popup-theme($theme);

		kn-menu-activator {
			em {
				color: map-get($theme, background); // sass-lint:disable-line no-color-literals
			}

			& > * {
				transition: color 0.5s ease;

				&:hover, &:focus {
					color: map-get($theme, background);
					background-color: map-get($theme, grid-menu-activated-hover);
				}
			}

			& > span.template-name {
				color: map-get($theme, grid-active-selection);
			}
		}
	}

	@at-root kn-attached-portal .kn-menu-content.kn-grid-query-content {
		left: calc(50% - 900px);
		background-color: map-get($theme, background);
		box-shadow: 0 2px 5px $grid-menu-shadow-color;

		&::before {
			left: calc(50% + 375px);
		}
	}
}
