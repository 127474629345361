@import '../shared/flat-button';

@mixin strio-query-filter() {
	$query-filter-select-shadow-color: rgba(0, 0, 0, 0.1);
	$query-filter-select-hover-shadow-color: rgba(0, 0, 0, 0.2);

	kn-query-filter {
		.query-filter > div[knFilterGroup] {
			display: block;
			min-width: 1000px;
			padding: 20px;
		}

		.filter-group {
			margin: auto;

			.new-filter-actions {
				align-items: center;
				display: flex;

				button {
					margin: 0 0 0 10px;
					padding: 5px 10px;
					border-radius: 3px;
					border: none;
					height: 30px;
					text-transform: uppercase;
				}

				.coalescing-operator {
					display: flex;
					align-items: center;
					margin-right: 10px;

					&::before {
						content: '';
						display: block;
						flex: 1;
						height: 1px;
					}
				}

				kn-select {
					width: 200px;
				}
			}
		}

		ul {
			list-style-type: none;
			padding: 0;
			margin: 0;
		}

		li {
			padding: 10px 0 10px 10px;
		}

		li.boolean-operator {
			padding: 0;
			position: relative;
			height: 1px;

			span {
				position: absolute;
				display: block;
				padding: 0 10px;
				font-size: 14px;
				height: 20px;
				line-height: 20px;
				top: -10px;
				left: 20px;
				text-transform: uppercase;
			}
		}

		li[knFilterItem] {
			padding: 10px 0 10px 10px;
			display: flex;
			align-items: center;
			height: 40px;

			& > span {
				margin: 5px;
			}

			&:first-child {
				padding-top: 0;
			}
		}

		.filter-group {
			.filter-name, .coalescing-operator {
				width: 225px;
			}

			.coalescing-operator {
				width: 190px;
			}

			.filter-group {
				.filter-name, .coalescing-operator {
					width: 205px;
				}

				.coalescing-operator {
					width: 170px;
				}

				.filter-group {
					.filter-name, .coalescing-operator {
						width: 185px;
					}

					.coalescing-operator {
						width: 150px;
					}

					.filter-group {
						.filter-name, .coalescing-operator {
							width: 165px;
						}

						.coalescing-operator {
							width: 130px;
						}
					}
				}
			}
		}

		.filter-name input {
			font-weight: bold;
		}

		.filter-operator {
			width: 150px;

			kn-input input {
				text-align: center;
			}

			& > * {
				width: 100%;
			}
		}

		.filter-value {
			flex: 1;

			.presenter {
				flex: 1;
				display: flex;
				align-items: center;

				kn-input, kn-select {
					flex: 1;
				}

				kn-date-input span.required {
					display: none;
				}

				& > span {
					margin: 0 10px;

					&:first-child {
						margin-left: 0;
					}
				}
			}
		}

		.filter-actions {
			width: 70px;
			text-align: right;
		}

		.filter-actions button {
			@include strio-flat-button();
			height: 34px;
		}

		.footer {
			padding: 20px;
			text-align: right;
			display: flex;

			button {
				padding: 5px 10px;
				margin-left: 20px;
				border-radius: 3px;
				border: none;
				height: 30px;
				text-transform: uppercase;
			}

			.left {
				text-align: left;
				flex: 1;

				button {
					margin-left: 0;
					margin-right: 20px;
				}
			}
		}

		.filter-placeholder {
			width: 400px;
			margin: 30px auto 50px auto;
			text-align: center;

			span.placeholder {
				display: block;
			}

			& > i.material-icons {
				font-size: 150px;
			}

			p {
				margin-top: -10px;
				margin-bottom: 50px;
				font-size: 24px;
				display: none;
			}

			kn-select .kn-select-container {
				padding: 7px;
				transition: 0.3s all ease;
				box-shadow: 0 2px 10px $query-filter-select-shadow-color;

				&:hover {
					box-shadow: 0 2px 20px $query-filter-select-hover-shadow-color;
				}

				&:active {
					transition: none;
					box-shadow: none;
				}
			}
		}
	}
}

@mixin strio-query-filter-theme($theme) {
	kn-query-filter {
		.query-filter > div[knFilterGroup] {
			color: map-get($theme, text);
		}

		.filter-group {
			.new-filter-actions {
				button {
					background-color: map-get($theme, em);
				}

				.coalescing-operator::before {
					background-color: map-get($theme, em);
				}
			}

			.filter-group {
				border-left: 10px solid map-get($theme, em);
			}
		}

		li.boolean-operator {
			background-color: map-get($theme, em);

			span {
				color: map-get($theme, text-secondary);
				background-color: map-get($theme, background);
			}
		}

		.filter-name [kn-suffix] {
			color: map-get($theme, text-secondary);
		}

		.filter-operator kn-input [kn-suffix] {
			color: map-get($theme, text-secondary);
		}

		.filter-value .presenter > span {
			color: map-get($theme, text-secondary);
		}

		.filter-actions button {
			@include strio-flat-button-theme($theme);
		}

		.footer {
			background-color: map-get($theme, tone);

			button {
				background-color: map-get($theme, em);
			}
		}

		.filter-placeholder {
			& > i.material-icons {
				color: map-get($theme, placeholder);
			}

			kn-select {
				.kn-select-container {
					border: 1px solid map-get($theme, line);

					&:active {
						background-color: map-get($theme, hover);
					}
				}

				.placeholder {
					color: map-get($theme, text);
				}
			}
		}
	}
}
