@import '../shared/flat-button';

@mixin strio-kcuni-settings() {
	kn-kcuni-settings {
		display: block;
		margin: 20px;

		p strong {
			font-weight: 400;
		}

		.settings {
			display: flex;
			flex-direction: row;
			align-items: stretch;
			padding: 10px;
			margin: 0 -10px 10px;

			kn-input {
				flex: 1;
			}

			button {
				@include strio-flat-button();
				margin-left: 10px;
			}
		}

		.message {
			padding: 10px;
			vertical-align: center;

			i {
				vertical-align: bottom;
			}
		}

		.doc-link, {
			display: block;
			padding-top: 10px;
			margin-bottom: 20px;
			float: left;

			&.app-link {
				float: right;
			}
		}
	}
}

@mixin strio-kcuni-settings-theme($theme) {
	kn-kcuni-settings {
		p {
			color: map-get($theme, text-secondary);

			strong {
				color: map-get($theme, text);
			}
		}

		.settings {
			button {
				@include strio-flat-button-theme($theme);
			}

			&:hover {
				background-color: map-get($theme, hover);
			}
		}

		.message {
			color: map-get($theme, warn);
			background-color: rgba(map-get($theme, warn), 0.2); // sass-lint:disable-line no-color-literals
		}
	}
}
