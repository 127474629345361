@import 'grid-menu';

@mixin strio-grid-menu-button() {
	@include strio-grid-menu();

	kn-menu-activator {
		padding: 0 7px;

		i {
			padding: 5px 0;

			&.drop-down {
				padding: 5px 0;
				margin-right: -5px;
			}
		}
	}

	kn-menu-content:not(.kn-menu-content) {
		ul {
			list-style-type: none;
			padding: 0;
			margin: 0;
		}

		li {
			padding: 0;
			margin: 0;
			display: flex;
			justify-content: space-between;
		}

		li span {
			white-space: nowrap;
			font-size: 16px;
			cursor: pointer;
			display: inline-block;
			line-height: 24px;
			height: 24px;
			padding: 5px;
			flex: 1;
		}
	}

	@at-root kn-attached-portal .kn-grid-button-content {
		ul {
			list-style-type: none;
			padding: 0;
			margin: 0;
		}

		li {
			padding: 0;
			margin: 0;
			display: flex;
			justify-content: space-between;
		}

		li span {
			white-space: nowrap;
			font-size: 16px;
			cursor: pointer;
			display: inline-block;
			line-height: 24px;
			height: 24px;
			padding: 5px;
			flex: 1;
		}
	}
}

@mixin strio-grid-menu-button-theme($theme) {
	@include strio-grid-menu-theme($theme);

	kn-menu-content:not(.kn-menu-content) {
		li span {
			color: rgba(map-get($theme, background), 0.7); // sass-lint:disable-line no-color-literals

			&:hover {
				color: map-get($theme, background);
				background-color: map-get($theme, grid-menu-hover);
			}
		}
	}

	@at-root kn-attached-portal .kn-grid-button-content {
		li span {
			color: rgba(map-get($theme, background), 0.7); // sass-lint:disable-line no-color-literals

			&:hover {
				color: map-get($theme, background);
				background-color: map-get($theme, grid-menu-hover);
			}
		}
	}
}
