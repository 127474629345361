@mixin strio-toast() {
	$toast-shadow-color: rgba(0, 0, 0, 0.3);

	kn-toast > div {
		position: fixed;
		display: flex;
		flex-direction: row;
		justify-content: center;
		left: 50px;
		bottom: 50px;
		z-index: 1000;
		box-shadow: 0 2px 3px $toast-shadow-color;
		border-radius: 2px;

		&.kn-enter, &.kn-leave {
			transition: 0.3s all ease;
		}

		&.kn-enter, &.kn-leave.kn-leave-active {
			left: 35px;
			opacity: 0;
		}

		&.kn-leave, &.kn-enter.kn-enter-active {
			left: 50px;
			opacity: 1;
		}

		.content {
			display: flex;
			flex-direction: column;
			padding: 15px 20px;

			span, strong {
				display: block;
			}
		}

		a {
			display: flex;
			justify-content: center;
			flex-direction: column;
			padding: 15px;
			text-align: center;

			&, &:hover, &:focus, &:active {
				text-decoration: none;
			}
		}
	}
}

@mixin strio-toast-theme($theme) {
	$toast-foreground-color: white;
	$toast-background-color: black;

	kn-toast > div {
		background-color: rgba($toast-background-color, 0.75);
		color: $toast-foreground-color;

		a {
			background-color: rgba($toast-background-color, 0.2);
			color: $toast-foreground-color;
			border-left: 1px solid rgba($toast-foreground-color, 0.15);

			&:hover {
				background-color: rgba($toast-background-color, 0.5);
			}

			&:active {
				background-color: $toast-background-color;
			}
		}
	}
}
