@import '../shared/flat-button';

@mixin strio-common-roles() {
	kn-roles-table .role-permissions {
		position: relative;
		margin: 0 10px 76px 10px;

		& > .scrollable {
			overflow-x: auto;
			margin: 0 54px 0 220px;
		}

		table {
			border-collapse: separate;
			border-spacing: 0;

			thead th {
				position: relative;
				width: 54px;
				min-width: 54px;
				height: 270px;
				padding: 0;

				&.permission:not(:nth-child(2))::before {
					content: '';
					left: 0;
					bottom: 10px;
					position: absolute;
					display: block;
					width: 1px;
					height: calc(100% - 20px);
				}
			}

			tbody td {
				padding: 0;
				height: 54px;
			}

			tbody.permissions-matrix td kn-checkbox {
				justify-content: center;
			}

			tbody.add-new-input td {
				height: 0;
			}

			div.rotate {
				display: flex;
				flex-direction: column;
				justify-content: center;
				position: absolute;
				height: 54px;
				bottom: 0;
				left: 100%;
				transform: rotate(270deg);
				transform-origin: 0 100%;
				text-align: left;
			}

			th, td {
				&.name {
					position: absolute;
					left: 0;
					width: 220px;
					text-align: center;
					vertical-align: middle;

					kn-input {
						margin: 10px;

						input {
							font-weight: 700;
						}
					}
				}

				&.spacer {
					width: 100%;
					min-width: 0;
				}

				&.actions {
					position: absolute;
					right: 0;
					width: 54px;
				}
			}
		}

		.actions button {
			@include strio-flat-button();
			margin: 10px;
			height: 34px;
		}
	}

	kn-roles-table .role-permissions, .permission-actions-select {
		span {
			&.name, &.description {
				display: block;
				white-space: nowrap;
				padding: 1px 10px;
			}

			&.name {
				font-size: 16px;
				font-weight: bold;
			}

			&.description {
				font-size: 14px;
				font-weight: 400;
				opacity: 0.7;
			}
		}
	}

	kn-attached-portal .permission-actions-select {
		position: absolute;
		z-index: 100;
		filter: drop-shadow(0 3px 12px rgba(0, 0, 0, 0.3));
		padding: 10px;
		border-radius: 5px;
		transform: translate(calc(-50% + 25px), 5px);

		& > div {
			padding: 5px;

			kn-checkbox label {
				flex: 1;
			}
		}

		&::before {
			position: absolute;
			left: calc(50% - 10px);
			top: -10px;
			content: '';
			display: block;
			width: 0;
			height: 0;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
		}
	}
}

@mixin strio-common-roles-theme($theme) {
	kn-roles-table .role-permissions {
		table {
			thead th {
				border-bottom: 3px solid map-get($theme, line-disabled);
			}

			tbody.permissions-matrix {
				td {
					border-bottom: 1px solid map-get($theme, separator);
				}

				tr:hover > td {
					background-color: map-get($theme, hover);
				}
			}
		}

		.actions button {
			@include strio-flat-button-theme($theme);
		}
	}

	kn-attached-portal .permission-actions-select {
		background-color: map-get($theme, background);

		&::before {
			border-bottom: 10px solid map-get($theme, background);
		}
	}
}
