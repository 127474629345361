// sass-lint:disable no-color-literals

@import 'theme';

@import 'dashboard';
@import 'devices';
@import 'signin';
@import 'people-counter';
@import 'colors';
@import 'mics';
@import 'service-records-display';

kn-datagrid {
	display: block;
	margin: 20px;
	background-color: white;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	border: 1px solid rgba(0, 0, 0, 0.15);

	kn-reordable-handler {
		min-height: 20px;
	}

	kn-app-in-grid-actions {
		opacity: 0;
		display: flex;
		align-items: center;
		justify-content: center;

		a {
			@include strio-flat-button();
			@include strio-flat-button-theme($light-theme);
			height: 24px;
			padding: 2px;
		}

		a + a {
			margin-left: 1px;
		}
	}

	tr:hover kn-app-in-grid-actions {
		opacity: 1;
	}
}

$header-color: mix(map-get($light-theme, primary), map-get($light-theme, accent), 50%);

header.liner, header.liner kn-menu.expanded, kn-attached-portal .header-liner.kn-menu-content {
	background-color: $header-color;
}

header.liner .logo {
	display: flex;
	margin-right: 15px;
}

.app-loading-screen .logo {
	width: 300px;
}

kn-attached-portal kn-database-selector {
	.filter {
		display: flex;
		background-color: rgba(0, 0, 0, 0.05);
		border-bottom: 1px solid rgba(0, 0, 0, 0.2);

		& > * {
			line-height: 20px;
		}

		.clear, .counter {
			flex: 0 0;
		}

		.clear {
			display: inline-block;
			padding: 10px 5px 10px 20px;
			color: transparent;
			user-select: none;
			background: url('../img/search.svg') no-repeat center;
			opacity: 0.5;
			transition: background-image 0.2s ease-in-out, opacity 0.2s ease;

			&:hover {
				opacity: 1;
				color: transparent;
			}
		}

		&:hover .clear {
			background-image: url('../img/clear.svg');
		}

		.counter {
			display: inline-block;
			padding: 10px;
			font-size: 12px;
			color: rgba(0, 0, 0, 0.5);
		}

		input {
			padding: 10px 10px 10px 5px;
			outline: none;
			flex: 1 1;
			border: none;
			font-size: 16px;
			background-color: transparent;
			min-width: 0;
		}
	}

	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		overflow-y: auto;
		max-height: calc(80% - 100px);
	}

	ul li {
		&:not(:last-child) {
			border-bottom: 1px solid rgba(0, 0, 0, 0.15);
		}

		a {
			position: relative;
			padding: 10px 10px 10px 17px;
			color: rgba(0, 0, 0, 0.7);
			text-decoration: none;
			display: block;
			background-color: transparent;
			transition: all 0.5s ease;
			background-size: 200% 100%;
			background-position: 300% 0%;
		}

		a::before {
			content: '';
			display: block;
			position: absolute;
			top: calc(50% - 10px);
			left: -3px;
			width: 0;
			height: 0;
			transition: all 0.3s linear;
			z-index: 10;
			border-top: 10px solid transparent;
			border-bottom: 10px solid transparent;
			border-left: 10px solid rgba(64, 96, 192, 0);
		}

		a.active::before, a:focus::before {
			left: 0;
		}

		a:hover {
			text-decoration: none;
		}

		strong {
			display: inline-block;
		}

		span.uid {
			float: right;
		}

		span.description {
			display: block;
			font-style: italic;
		}
	}
}

kn-database-selector,
kn-attached-portal kn-database-selector {
	margin: 0;
	padding: 0;
	width: 100%;
	display: block;
	border: 1px solid rgba(0, 0, 0, 0.2); // sass-lint:disable-line no-color-literals
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // sass-lint:disable-line no-color-literals

	.filter {
		display: flex;
		background-color: rgba(0, 0, 0, 0.05); // sass-lint:disable-line no-color-literals
		border-bottom: 1px solid rgba(0, 0, 0, 0.2); // sass-lint:disable-line no-color-literals

		& > * {
			line-height: 20px;
		}

		.clear, .counter {
			flex: 0 0;
		}

		.clear {
			display: inline-block;
			padding: 10px 5px 10px 20px;
			color: transparent;
			user-select: none;
			background: url('../img/search.svg') no-repeat center;
			opacity: 0.5;
			transition: background-image 0.2s ease-in-out, opacity 0.2s ease;

			&:hover {
				opacity: 1;
				color: transparent;
			}
		}

		&:hover .clear {
			background-image: url('../img/clear.svg');
		}

		.counter {
			display: inline-block;
			padding: 10px;
			font-size: 12px;
			color: rgba(0, 0, 0, 0.5); // sass-lint:disable-line no-color-literals
		}

		input {
			padding: 10px 10px 10px 5px;
			outline: none;
			flex: 1 1;
			border: none;
			font-size: 16px;
			background-color: transparent;
			min-width: 0;
		}
	}

	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		overflow-y: auto;
	}

	ul li {
		&:not(:last-child) {
			border-bottom: 1px solid rgba(0, 0, 0, 0.15); // sass-lint:disable-line no-color-literals
		}

		a {
			position: relative;
			padding: 10px 10px 10px 17px;
			color: rgba(0, 0, 0, 0.7); // sass-lint:disable-line no-color-literals
			text-decoration: none;
			display: block;
			background-color: transparent;
			transition: all 0.5s ease;
			background-size: 200% 100%;
			background-position: 300% 0%;
		}

		a::before {
			content: '';
			display: block;
			position: absolute;
			top: calc(50% - 10px);
			left: -3px;
			width: 0;
			height: 0;
			transition: all 0.3s linear;
			z-index: 10;
			border-top: 10px solid transparent;
			border-bottom: 10px solid transparent;
			border-left: 10px solid rgba(64, 96, 192, 0); // sass-lint:disable-line no-color-literals
		}

		a.active::before, a:focus::before {
			left: 0;
		}

		a:hover {
			text-decoration: none;
		}

		strong {
			display: inline-block;
		}

		span.uid {
			float: right;
		}

		span.description {
			display: block;
			font-style: italic;
		}
	}
}

kn-attached-portal kn-database-selector ul {
	max-height: calc(85vh - 140px);
}

kn-textarea textarea {
	min-height: 100px;
}
