$primary-color: rgb(64, 96, 192) !default;
$background-color: rgb(255, 255, 255) !default;
$text-color: rgba(0, 0, 0, 0.9) !default;
$text-secondary-color: rgba(0, 0, 0, 0.6) !default;
$text-hint-color: rgba(0, 0, 0, 0.3) !default;
$text-hover-shadow-color: rgba(0, 0, 0, 0.5) !default;
$shadow-color: rgba(0, 0, 0, 0.3) !default;
$divider-color: rgba(0, 0, 0, 0.15) !default;
$hover-color: rgba(0, 0, 0, 0.1) !default;
$column-active-color: rgb(176, 176, 192) !default;
$resizing-mark-color: rgba(0, 0, 0, 0.2) !default;
$resizing-hover-color: rgba(0, 0, 0, 0.07) !default;
$resizing-active-color: rgba(0, 0, 0, 0.1) !default;
$resizing-guide-color: rgba(0, 0, 0, 0.7) !default;
$row-hover-color: rgba(0, 0, 0, 0.05) !default;
$row-child-color: rgba(224, 224, 64, 0.15) !default;
$row-divider-color: rgba(0, 0, 0, 0.1) !default;
$row-selected-color: rgba(0, 0, 0, 0.05) !default;
$row-dragging-shadow-color: rgba(0, 0, 0, 0.15) !default;
$icon-color: rgba(0, 0, 0, 0.9) !default;
$icon-secondary-color: rgba(0, 0, 0, 0.6) !default;
$column-group-background-color: rgba(0, 0, 0, 0.03) !default;
$column-number-color: rgba(0, 0, 0, 0.3) !default;
$column-highlight-color: rgb(128, 32, 32) !default;
$header-divider-color: rgba(0, 0, 0, 0.2) !default;
$header-loading-overlay-color: rgba(255, 255, 255, 0.5) !default;
