@import 'theme';

service-records-display {
	white-space: nowrap;
	margin: 10px 5px 10px 25px;
	color: map-get($light-theme, text-secondary);
	display: flex;
	align-items: center;

	i::after {
		content: "\00a0"; /* this is &nbsp; */
	}
}
