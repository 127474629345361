@import 'colors';

kn-datagrid {
	kn-resizable-handler {
		right: -11px;
		width: 21px;

		&::before {
			content: '';
			width: 1px;
			height: 4px;
			bottom: 0;
			left: 10px;
			display: block;
			position: absolute;
			background-color: $resizing-mark-color;
		}

		&:hover {
			background-color: $resizing-hover-color;
		}

		&.resizing {
			background-color: $resizing-active-color;
		}

		&.resizing::before {
			height: 200px;
			top: 0;
			background: linear-gradient($resizing-guide-color, transparent);
		}
	}

	th:last-child kn-resizable-handler {
		right: 0;
		width: 11px;

		&::before {
			display: none;
		}
	}
}
