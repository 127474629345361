@import '../shared/flat-button';
@import '../core/assets-url';

@mixin strio-grid-search() {
	kn-grid-search {
		position: relative;
		height: 50px;
		margin: -8px -8px -8px 0;

		input[type='search'] {
			border: none;
			padding: 0 10px 0 40px;
			height: 100%;
			width: 0;
			vertical-align: top;
			transition: 0.3s all ease;

			&:focus, &.active {
				width: 250px;
				outline: none;
			}
		}

		&::after {
			content: '';
			display: block;
			position: absolute;
			pointer-events: none;
			width: 50px;
			height: 50px;
			top: 0;
			left: 0;
			mask: assets-url('img/search.svg') no-repeat center;
			-webkit-mask: assets-url('img/search.svg') no-repeat center; // sass-lint:disable-line no-vendor-prefixes
		}
	}
}

@mixin strio-grid-search-theme($theme) {
	kn-grid-search {
		input[type='search'] {
			background-color: rgba(map-get($theme, foreground), 0.2); // sass-lint:disable-line no-color-literals
			color: map-get($theme, background);

			&:focus, &.active {
				background-color: rgba(map-get($theme, foreground), 0.4); // sass-lint:disable-line no-color-literals
			}

			&::placeholder {
				color: rgba(map-get($theme, background), 0.5); // sass-lint:disable-line no-color-literals
			}
		}

		&::after {
			background-color: map-get($theme, background);
		}
	}
}
