@import '../shared/flat-button';

@mixin strio-form-layout() {
	$form-layout-shadow-color: rgba(0, 0, 0, 0.1);

	.form-layout {
		.info-bar {
			margin: 15px;
			padding: 15px;
			box-shadow: 0 1px 7px $form-layout-shadow-color;

			strong {
				font-weight: 400;
			}
		}

		.form-grid {
			display: flex;
			flex-direction: column;
		}

		.form-box {
			display: flex;
			flex-direction: column;
			flex-grow: 1;
			margin: 3px;
			box-shadow: 0 1px 2px $form-layout-shadow-color;
			border-radius: 2px;

			.title {
				position: relative;
				display: flex;
				flex-direction: row;
				align-items: center;
				margin: 0;
				padding: 5px;
				height: 34px;
				line-height: 34px;

				h2 {
					flex: 1;
					padding-left: 8px;
					margin: 0;
					font-weight: 800;
					font-size: 1em;
					text-transform: uppercase;
				}

				.side-action {
					@include strio-flat-button();
					height: 24px;
					margin-left: 5px;
				}
			}

			.content {
				flex-grow: 1;
				padding: 0 15px 15px 15px;

				&:first-child {
					padding-top: 15px;
				}
			}

			.info-bar {
				margin: 0;
				box-shadow: none;
				border-top: none;
				border-left: none;
				border-right: none;
			}

			.container {
				display: flex;

				.content {
					flex: 1;
					width: 0;
				}

				.side-actions {
					display: flex;
					align-items: center;
					justify-content: center;

					a {
						@include strio-flat-button();
						height: 24px;
						margin: 10px;
					}
				}
			}

			.feature {
				border-radius: 2px 2px 0 0;
				flex-grow: 0;
			}

			.feature + .content {
				padding-top: 15px;
			}

			.alt {
				padding: 15px;
				flex-grow: 0;

				p {
					margin: 0.5em;
					padding: 0;
				}
			}

			.alt-actions {
				display: flex;

				a {
					@include strio-flat-button();
					display: flex;
					align-items: center;
					padding: 5px 7px;
					height: 24px;

					i.material-icons {
						margin-right: 5px;
					}
				}
			}
		}

		.form-inline-box {
			margin: 15px;

			.title h3 {
				flex: 1;
				padding: 2px 0 0 0;
				margin: 0;
				font-weight: 400;
				font-size: 16px;
				text-align: center;
			}
		}

		.form-expanssion {
			margin: 15px 0;
			z-index: 1;

			.form-box {
				margin: 0;
				transition: 0.2s margin ease;
				position: relative;
				box-shadow: none;

				&::before {
					content: '';
					position: absolute;
					display: block;
					width: 100%;
					height: 100%;
					z-index: -1;
					box-shadow: 0 1px 7px $form-layout-shadow-color;
				}
			}

			.form-box.expanded {
				margin: 30px 0;
			}

			.form-box:first-child {
				margin-top: 0;
			}

			.form-box:last-child {
				margin-bottom: 0;
			}
		}

		.row {
			display: flex;
			flex-direction: row;
			width: 100%;
		}

		.column {
			display: flex;
			flex-direction: column;
			width: 100%;

			.column-spacer {
				height: 100%;
			}
		}

		.form-input {
			flex: 1;
			position: relative;
			padding: 3px 0;
			min-width: 0;

			&.inlined {
				margin: 0;
				flex-grow: 0;
				min-width: auto;
			}

			& > * {
				margin: 0 10px;
			}

			& > input, & > select {
				width: calc(100% - 20px);
			}

			& > kn-control-messages {
				margin: -18px 0 0 15px;
			}
		}

		.flex-input input {
			width: 0;
		}

		.flex-0 {
			flex: unset;
		}

		.flex-1 {
			flex: 1;
		}

		.flex-2 {
			flex: 2;
		}

		.flex-3 {
			flex: 3;
		}

		.flex-4 {
			flex: 4;
		}

		.flex-5 {
			flex: 5;
		}

		.flex-6 {
			flex: 6;
		}

		.flex-start {
			justify-content: flex-start;
		}

		.flex-end {
			justify-content: flex-end;
		}

		.flex-middle {
			display: flex;
			align-items: center;
		}

		.row.flex-reverse {
			flex-direction: row-reverse;
		}

		.column.flex-reverse {
			flex-direction: column-reverse;
		}
	}
}

@mixin strio-form-layout-theme($theme) {
	.form-layout {
		.info-bar {
			color: rgba(map-get($theme, background), 0.7); // sass-lint:disable-line no-color-literals
			background-color: map-get($theme, accent);

			&.error {
				background-color: map-get($theme, warn);
			}

			strong {
				color: map-get($theme, background);
			}
		}

		.form-box {
			background-color: map-get($theme, background);

			.title {
				color: map-get($theme, form-card-text);

				.side-action {
					@include strio-flat-button-theme($theme);
				}
			}

			.container {
				& + .container {
					border-top: 1px solid map-get($theme, divider);
				}

				.side-actions {
					border-left: 1px solid map-get($theme, em);

					a {
						@include strio-flat-button-theme($theme);
					}
				}
			}

			.feature {
				background-color: rgba(map-get($theme, form-card-text), 0.1);
				border-top: 5px solid map-get($theme, form-card-text);

				&:not(:last-child) {
					border-bottom: 1px solid rgba(map-get($theme, form-card-text), 0.2);
				}
			}

			.alt {
				background-color: rgba(map-get($theme, info-background), 0.1); // sass-lint:disable-line no-color-literals
				border-top: 1px solid rgba(map-get($theme, info-background), 0.3); // sass-lint:disable-line no-color-literals
			}

			.alt-actions a {
				@include strio-flat-button-theme($theme);
			}
		}

		.form-inline-box {
			.title {
				background-color: transparent;
				color: map-get($theme, text-secondary);
				border-bottom: 3px solid map-get($theme, divider) !important;
			}

			.container {
				border-left: 1px solid map-get($theme, divider);
				border-right: 1px solid map-get($theme, divider);
				border-bottom: 1px solid map-get($theme, divider);
				background-color: rgba(map-get($theme, info-background), 0.1); // sass-lint:disable-line no-color-literals

				& + .container {
					border-top: none;
				}
			}
		}
	}
}
