@import 'colors';

@function assets-url($path) {
	@return unquote('url("../#{$path}")');
}

kn-datagrid {
	.tree-bar {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		left: 0;
		top: 0;
		width: 30px;
		height: 100%;

		& > a {
			color: transparent;
			font-size: 0;
			width: 30px;
			height: 30px;
			cursor: pointer;
		}
	}

	.tree-mark-source.expanded {
		background: transparent assets-url('img/tree-marks/tree-mark-source.svg') no-repeat center center;
	}

	.tree-mark-transit {
		background: transparent assets-url('img/tree-marks/tree-mark-transit.svg') no-repeat center center;
	}

	.tree-mark-fork {
		background: transparent assets-url('img/tree-marks/tree-mark-fork.svg') no-repeat center center;
	}

	.tree-mark-sink {
		background: transparent assets-url('img/tree-marks/tree-mark-sink.svg') no-repeat center center;
	}

	.tree-mark-source a {
		// sass-lint:disable-block no-vendor-prefixes
		-webkit-mask: assets-url('img/tree-marks/tree-expander.svg') no-repeat 50% 50%;
		mask: assets-url('img/tree-marks/tree-expander.svg') no-repeat 50% 50%;
		transform: rotate(0);
		transition: transform ease 0.3s, background-color ease 0.3s;
		background-color: $icon-secondary-color;

		&:hover {
			background-color: $primary-color;
		}

		&:active {
			background-color: $icon-color;
			transition: transform ease 0.3s;
		}
	}

	.tree-mark-source.expanded a {
		transform: rotate(90deg);
	}
}
