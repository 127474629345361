@import '../shared/flat-button';

@mixin strio-common-print-template() {
	$common-print-template-shadow-color: rgba(0, 0, 0, 0.3);

	app-print-template-edit {
		kn-codemirror {
			height: 100%;
			flex: 1;
		}

		& > kn-resizable-container {
			height: 100%;

			kn-draggable-handler.left {
				z-index: 2;
				height: 50px !important;
				top: calc(100% - 50px) !important;
			}

			.preview iframe {
				width: 100%;
				height: 100%;
				border: none;
			}

			// sass-lint:disable class-name-format
			.CodeMirror {
				height: 100%;
				overflow: visible;
				background: none;
			}

			.CodeMirror-scroll {
				margin: 0;
				padding: 0;
				overflow: visible !important;
			}

			.CodeMirror-sizer {
				border-right-width: 0 !important;
			}
			// sass-lint:enable class-name-format

			.box {
				width: 100%;
				height: 100%;
			}
		}

		form {
			width: 100%;
			height: 100%;
		}

		kn-tabs {
			flex-direction: column-reverse;

			a.sidepanel {
				@include strio-flat-button();

				margin: 0 6px 0 0;

				i {
					transition: transform 0.5s ease 0.3s;
				}

				&.active i {
					transform: rotate(180deg);
				}
			}
		}

		kn-tab {
			&.active {
				display: flex;
				flex-direction: column;
				flex: 1;
			}

			&.home {
				.box {
					flex: 1;
					display: flex;
					flex-direction: column;
					justify-content: center;
					margin: 30px auto;
				}

				.fields {
					width: 100%;
					max-width: 700px;
					margin: 0 auto;
				}

				i.banner {
					font-size: 192px;
					margin: 0 auto;
				}

				h1 {
					text-align: center;
					margin: 50px 0;
				}

				.form-container {
					width: 80%;
					height: 100%;
					margin: 50px auto;
				}

				.form-layout {
					display: flex;
					flex-direction: column;
					height: 100%;
				}
			}

			.no-print-context-placeholder {
				flex: 1;
				display: flex;
				flex-direction: column;
				justify-content: center;
				margin: 30px auto;

				i.banner {
					font-size: 128px;
					margin: 0 auto;
				}

				strong {
					font-family: 'Exo 2';
					text-align: center;
					font-weight: normal;
					font-size: 32px;
					margin: 20px 0 50px 0;
				}

				button {
					text-align: center;
				}
			}
		}

		kn-menu.errors kn-menu-activator {
			@include strio-flat-button();

			margin: 0 8px 0 0;
			opacity: 1;
		}
	}

	.app-print-template-edit-errors-popup {
		position: absolute;
		right: 8px;
		bottom: 50px;
		max-width: 1000px;
		overflow-x: auto;
		overflow-y: hidden;
		padding: 20px;
		z-index: 100;
		box-shadow: 0 3px 12px $common-print-template-shadow-color;

		[hidden] {
			display: none;
		}

		strong {
			display: block;
		}

		pre {
			font-size: 14px;
			margin-bottom: 0;
		}
	}
}

@mixin strio-common-print-template-theme($theme) {
	app-print-template-edit {
		background-color: map-get($theme, background);

		& > kn-resizable-container .preview {
			border-left: 1px solid map-get($theme, divider);
		}

		.preview div.tabs-viewport {
			background-color: mix(map-get($theme, primary), map-get($theme, background), 5%);
		}

		kn-codemirror {
			background-color: map-get($theme, background);
		}

		kn-tabs a.sidepanel {
			@include strio-flat-button-theme($theme);

			color: map-get($theme, icon);
		}

		kn-tab {
			&.home i.banner {
				color: rgba(map-get($theme, icon), 0.3); // sass-lint:disable-line no-color-literals
			}

			.no-print-context-placeholder {
				color: rgba(map-get($theme, text), 0.3); // sass-lint:disable-line no-color-literals
			}
		}

		kn-menu.errors kn-menu-activator {
			@include strio-flat-button-theme($theme);

			color: map-get($theme, warn);
		}
	}

	.app-print-template-edit-errors-popup {
		background-color: map-get($theme, background);
		color: map-get($theme, text);
	}
}
