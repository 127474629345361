@font-face {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 300;
	src: local('Source Sans Pro Light'), local('SourceSansPro-Light'), url('../fonts/sourcesanspro-300.woff') format('woff');
}

@font-face {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 400;
	src: local('Source Sans Pro'), local('SourceSansPro-Regular'), url('../fonts/sourcesanspro-400.woff') format('woff');
}

@font-face {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 600;
	src: local('Source Sans Pro Semibold'), local('SourceSansPro-Semibold'), url('../fonts/sourcesanspro-600.woff') format('woff');
}

@font-face {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 700;
	src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'), url('../fonts/sourcesanspro-700.woff') format('woff');
}

@font-face {
	font-family: 'Source Sans Pro';
	font-style: italic;
	font-weight: 300;
	src: local('Source Sans Pro Light Italic'), local('SourceSansPro-LightIt'), url('../fonts/sourcesanspro-300it.woff') format('woff');
}

@font-face {
	font-family: 'Source Sans Pro';
	font-style: italic;
	font-weight: 400;
	src: local('Source Sans Pro Italic'), local('SourceSansPro-It'), url('../fonts/sourcesanspro-400it.woff') format('woff');
}

@font-face {
	font-family: 'Source Sans Pro';
	font-style: italic;
	font-weight: 600;
	src: local('Source Sans Pro Semibold Italic'), local('SourceSansPro-SemiboldIt'), url('../fonts/sourcesanspro-600it.woff') format('woff');
}

@font-face {
	font-family: 'Source Sans Pro';
	font-style: italic;
	font-weight: 700;
	src: local('Source Sans Pro Bold Italic'), local('SourceSansPro-BoldIt'), url('../fonts/sourcesanspro-700it.woff') format('woff');
}
