@import '../core/assets-url';

@mixin strio-header-liner-database-menu() {
	header.liner .database-menu {
		&::before, &::after {
			content: '';
			position: absolute;
			top: 20px;
			width: 1px;
			height: 40px;
			transition: top 0.5s ease;
		}

		&::before {
			left: 0;
		}

		&::after {
			right: 0;
		}

		&.expanded, &:hover {
			&::before, &::after {
				top: -50px;
			}
		}

		&.database-menu-hidden {
			display: none;
		}

		kn-database-selector {
			border: none;

			.filter {
				font-size: 0;

				.clear {
					padding: 0 20px;
				}

				&:hover .clear {
					background-image: assets-url('img/clear.svg');
				}
			}

			ul {
				max-height: 70%;

				li a {
					&.active::before, &:focus::before {
						left: 0;
					}

					&:hover {
						text-decoration: none;
					}
				}
			}
		}

		kn-menu-activator {
			flex-direction: column;
			justify-content: center;
			min-width: 120px;
			height: 48px;
			padding-right: 40px;

			strong {
				display: block;
				font-weight: normal;
			}

			.description {
				display: block;
			}

			&::after {
				content: '';
				position: absolute;
				right: 12px;
				top: 32px;
				width: 16px;
				height: 16px;
				mask: assets-url('img/selector-expand.svg') no-repeat center;
				-webkit-mask: assets-url('img/selector-expand.svg') no-repeat center; // sass-lint:disable-line no-vendor-prefixes
				transform: rotate(-90deg);
				transition: transform 0.5s ease;
			}

			&:hover::after {
				transform: rotate(0deg);
			}

		}

		&.expanded kn-menu-activator::after {
			transform: rotate(-180deg);
		}
	}
}

@mixin strio-header-liner-database-menu-theme($theme) {
	$database-chavron-color: mix(map-get($theme, primary), map-get($theme, background), 60%);

	header.liner .database-menu {
		&::before, &::after {
			background-color: rgba(map-get($theme, background), 0.1); // sass-lint:disable-line no-color-literals
		}

		kn-database-selector {
			.filter {
				background-color: rgba(map-get($theme, background), 0.05); // sass-lint:disable-line no-color-literals
				border-bottom: 1px solid rgba(map-get($theme, background), 0.15); // sass-lint:disable-line no-color-literals

				.counter {
					color: rgba(map-get($theme, background), 0.5); // sass-lint:disable-line no-color-literals
				}

				input {
					color: map-get($theme, background);

					&::placeholder {
						color: rgba(map-get($theme, background), 0.5); // sass-lint:disable-line no-color-literals
					}
				}
			}

			ul li {
				&:not(:last-child) {
					border-bottom: 1px solid rgba(map-get($theme, background), 0.15); // sass-lint:disable-line no-color-literals
				}

				a {
					color: rgba(map-get($theme, background), 0.7); // sass-lint:disable-line no-color-literals

					&::before {
						border-left: 7px solid rgba($database-chavron-color, 0);
					}

					&.active::before, &:focus::before {
						border-left: 7px solid rgba($database-chavron-color, 1);
					}
				}

				strong {
					color: rgba(map-get($theme, background), 1); // sass-lint:disable-line no-color-literals
				}

				span.uid {
					color: mix(map-get($theme, primary), map-get($theme, background), 60%);
				}
			}
		}

		@at-root kn-attached-portal kn-database-selector {
			.filter {
				background-color: rgba(map-get($theme, background), 0.05); // sass-lint:disable-line no-color-literals
				border-bottom: 1px solid rgba(map-get($theme, background), 0.15); // sass-lint:disable-line no-color-literals

				.counter {
					color: rgba(map-get($theme, background), 0.5); // sass-lint:disable-line no-color-literals
				}

				input {
					color: map-get($theme, background);

					&::placeholder {
						color: rgba(map-get($theme, background), 0.5); // sass-lint:disable-line no-color-literals
					}
				}
			}

			ul li {
				&:not(:last-child) {
					border-bottom: 1px solid rgba(map-get($theme, background), 0.15); // sass-lint:disable-line no-color-literals
				}

				a {
					color: rgba(map-get($theme, background), 0.7); // sass-lint:disable-line no-color-literals

					&::before {
						border-left: 7px solid rgba($database-chavron-color, 0);
					}

					&.active::before, &:focus::before {
						border-left: 7px solid rgba($database-chavron-color, 1);
					}
				}

				strong {
					color: rgba(map-get($theme, background), 1); // sass-lint:disable-line no-color-literals
				}

				span.uid {
					color: mix(map-get($theme, primary), map-get($theme, background), 60%);
				}
			}
		}

		kn-menu-activator {
			&::after {
				background-color: rgba(map-get($theme, background), 0.2); // sass-lint:disable-line no-color-literals
			}

			strong {
				color: map-get($theme, background);
			}
		}
	}
}
