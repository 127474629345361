@mixin strio-header-liner-navigation() {
	header.liner {
		& > div:first-child, kn-navbar {
			flex: 1;
		}

		nav > ul, kn-navbar > ul {
			height: 80px;
			margin: 0 10px;
			padding: 0;
			list-style-type: none;
			display: flex;

			& > li {
				display: inline-block;
				margin: 0;
				padding: 0;

				& > a, & > kn-menu-activator {
					display: block;
					margin: 10px 3px;
					height: 58px;
					padding: 0 18px;
					line-height: 58px;
					transition: 0.3s all ease;
					border: 1px solid transparent;
					outline: none;

					&, &:hover, &:focus {
						text-decoration: none;
					}
				}
			}
		}

		kn-navbar {
			kn-menu {
				margin: 10px 3px;
				height: 60px;
				padding: 0 !important;
			}

			kn-menu-activator {
				height: 60px;
				padding: 0 8px;
				align-items: center;
			}
		}
	}

	@at-root kn-attached-portal .kn-navbar-expansion {
		ul {
			margin: 0 10px;
			padding: 0;
			list-style-type: none;

			& > li {
				margin: 0;
				padding: 0;

				& > a {
					display: block;
					padding: 0 18px;
					transition: 0.3s all ease;
					border: 1px solid transparent;
					outline: none;

					&, &:hover, &:focus {
						text-decoration: none;
					}
				}
			}
		}
	}
}

@mixin strio-header-liner-navigation-theme($theme) {
	header.liner {
		nav, kn-navbar {
			& > ul > li {
				// sass-lint:disable nesting-depth
				& > a, & > kn-menu-activator {
					color: rgba(map-get($theme, background), 0.7); // sass-lint:disable-line no-color-literals

					&.router-link-active {
						background-color: rgba(map-get($theme, background), 0.1); // sass-lint:disable-line no-color-literals
						color: map-get($theme, background);
					}

					&:hover {
						background-color: rgba(map-get($theme, background), 0.07); // sass-lint:disable-line no-color-literals
						color: map-get($theme, background);
					}

					&:not(.router-link-active):focus {
						border: 1px solid rgba(map-get($theme, background), 0.2); // sass-lint:disable-line no-color-literals
					}
				}
			}
		}
	}

	@at-root kn-attached-portal .kn-navbar-expansion {
		& > ul > li {
			// sass-lint:disable nesting-depth
			& > a, & > kn-menu-activator {
				color: rgba(map-get($theme, background), 0.7); // sass-lint:disable-line no-color-literals

				&.router-link-active {
					background-color: rgba(map-get($theme, background), 0.1); // sass-lint:disable-line no-color-literals
					color: map-get($theme, background);
				}

				&:hover {
					background-color: rgba(map-get($theme, background), 0.07); // sass-lint:disable-line no-color-literals
					color: map-get($theme, background);
				}

				&:not(.router-link-active):focus {
					border: 1px solid rgba(map-get($theme, background), 0.2); // sass-lint:disable-line no-color-literals
				}
			}
		}
	}
}
