// sass-lint:disable no-color-literals

kn-app-signin {
	.signin-user {
		position: absolute;
		top: 0;
		right: 0;
		margin: 20px;
		color: rgba(0, 0, 0, 0.4);
		z-index: 1;

		em {
			color: rgba(0, 0, 0, 0.8);
			font-style: normal;
			padding: 0 0.5em 0 0.3em;
		}
	}

	.signin-box {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: calc(100% - 100px);

		img.logo {
			width: 380px;
		}

		form {
			width: 300px;
		}

		.form-input {
			margin: 10px 0;
		}

		.form-button {
			margin-top: 30px;
		}

		// sass-lint:disable no-vendor-prefixes
		kn-input input:-webkit-autofill {
			-webkit-box-shadow: 0 0 0 1000px rgb(240, 240, 240) inset;
		}
		// sass-lint:enable no-vendor-prefixes

		&::before {
		    content: '';
		    flex: 1;
		}
	}

	.version {
		position: absolute;
		right: 30px;
		bottom: 30px;
		padding: 0;
		margin: 0;
		color: rgba(0, 0, 0, 0.5);

		strong {
			font-weight: normal;
			color: rgba(0, 0, 0, 0.8);
		}
	}

	footer {
		border-top: 1px solid rgba(0, 0, 0, 0.1);
		background-color: rgba(0, 0, 0, 0.05);
		margin: 0;
		padding: 0 30px;
		height: 99px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		p {
			color: rgba(0, 0, 0, 0.5);

			strong {
				color: rgba(0, 0, 0, 0.7);
				display: block;
			}
		}

		.company-logo img {
			opacity: 0.7;
			height: 36px;
		}
	}

	.box {
		position: relative;
	}

	.box, .box > div, .box > form {
		flex: 5;
		width: 300px;
	}

	.database-selection {
		height: 100%;
	}

	kn-database-selector {
		display: flex;
		flex-direction: column;
		max-height: calc(100% - 50px);
	}

	.box > form, .box > div {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 20px;
	}

	.box > form.kn-enter,
	.box > form.kn-leave,
	.box > div.kn-enter,
	.box > div.kn-leave {
		transition: 0.3s all linear;
	}

	.box > form.kn-enter,
	.box > form.kn-leave.kn-leave-active {
		left: -300px;
		opacity: 0;
	}

	.box > form.kn-leave,
	.box > form.kn-enter.kn-enter-active,
	.box > div.kn-leave,
	.box > div.kn-enter.kn-enter-active {
		left: 0;
		opacity: 1;
	}

	.box > div.kn-enter,
	.box > div.kn-leave.kn-leave-active {
		left: 300px;
		opacity: 0;
	}
}

kn-app-signin kn-database-selector {
	ul li {
		a.active::before, a:focus::before {
			border-left: 10px solid rgba(64, 96, 192, 1);
		}

		a:hover {
			background-color: rgba(0, 0, 0, 0.05);
		}

		strong {
			color: rgba(0, 0, 0, 1);
		}

		span.uid {
			color: rgb(64, 96, 192);
		}
	}
}

.database-selection > a {
	text-align: center;
	display: block;
	margin-top: 20px;
}
