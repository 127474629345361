@import '../shared/raised-button';
@import '../shared/loader-bar';

@mixin strio-form-container() {
	@keyframes reveal-back-link {
		to {
			opacity: 1;
			left: calc(50% - 685px);
		}
	}

	@include strio-interpolated-loader-bar('.form-container.heading, .form-container .form-loader-bar');

	.form-container {
		margin: 30px 0;

		&.heading {
			position: relative;
			margin: 0;
			padding: 0;
			transition: 0.5s box-shadow ease;

			> .content {
				max-width: 1200px;
				margin: auto;
				display: flex;
			}

			h1 {
				font-size: 32px;
				max-width: 1200px;
				margin: auto;
				flex: 1;
			}

			.form-buttons {
				display: flex;
				align-items: center;
			}
		}

		h1, p, .form-layout {
			margin: auto;
			max-width: 1200px;
		}

		h1 {
			padding: 20px 0;
			margin: 0 auto;
			border: none;
		}

		hr {
			border: none;
			height: 1px;
			margin: 10px -15px;
			background-color: rgba(0, 0, 0, 0.1);
		}

		.form-back {
			position: absolute;
			opacity: 0;
			left: calc(50% - 750px);
			top: calc(50% - 32px);
			transition: 0.3s all ease;
			animation-name: reveal-back-link;
			animation-duration: 1s;
			animation-fill-mode: forwards;

			&::after {
				content: '';
				position: absolute;
				display: block;
				width: 64px;
				height: 64px;
				left: 20px;
				border-radius: 50%;
				transition: 0.3s all ease;
				transform: rotateY(45deg);
			}

			i {
				position: absolute;
				z-index: 10;
				font-size: 48px;
				line-height: 64px;
				text-align: center;
				width: 64px;
				height: 64px;
			}

			&:hover::after, &:focus::after {
				left: 0;
				transform: rotateY(0);
			}

			&:active::after {
				transition: none;
			}
		}
	}

	kn-sticky.sticked > .form-container.heading {
		position: fixed !important;
		box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
	}

	.form-footer {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		margin-top: 20px;

		div:only-child {
			margin-left: auto;
		}
	}

	.form-actions-info {
		display: flex;
		flex-grow: 1;
		justify-content: flex-end;
		align-items: center;
		margin: 0 1em;
		white-space: nowrap;

		i.material-icons {
			padding: 0 0.3em;
		}
	}

	.form-buttons {
		text-align: right;

		button {
			@include strio-raised-button();
			width: auto;
			margin-left: 10px;
			padding: 0 30px;
		}
	}
}

@mixin strio-form-container-theme($theme) {
	@include strio-interpolated-loader-bar-theme('.form-container.heading, .form-container .form-loader-bar', $theme);

	.form-container {
		&.heading {
			background-color: map-get($theme, background);
		}

		.form-back {
			color: map-get($theme, link-back);

			&:hover, &:focus {
				color: map-get($theme, background);

				&::after {
					background-color: map-get($theme, link-back);
				}
			}

			&:active::after {
				background-color: map-get($theme, link-back-active);
			}
		}
	}

	.form-actions-info {
		color: map-get($theme, info);
	}

	.form-buttons button {
		@include strio-raised-button-theme($theme);
	}
}
