// sass-lint:disable no-color-literals

@import 'theme';

@include strio-interpolated-loader-bar('.heading.loading-bar');
@include strio-interpolated-loader-bar-theme('.heading.loading-bar', $light-theme);

app-dashboard {
	h2 {
		font-weight: normal;
		margin: 0 5px;
		padding: 0 0 5px 0;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	}

	p.address {
		font-size: small;
		margin: 0 5px;
		padding: 5px 0 20px 0;
		opacity: 0.5;
	}

	.no-device {
		font-style: italic;
		text-align: center;
		opacity: 0.5;
	}

	.devices-tiles {
		list-style-type: none;
		display: grid;
		grid-gap: 15px;
		grid-template-columns: repeat(auto-fit, minmax(250px, 0.25fr));
		margin: 0;
		padding: 0 0 50px 0;

		li {
			margin: 0;
			padding: 0;
		}
	}

	.device-tile {
		position: relative;
		margin: 0;
		padding: 0;
		text-align: left;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: stretch;
		background-color: white;
		border: none;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
		border-radius: 2px;
		transition: all 300ms ease;

		&:not([disabled]) {
			&:not(.live) {
				&::after {
					content: '';
					position: absolute;
					right: 8px;
					top: 8px;
					width: 24px;
					height: 24px;
					background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000"><path d="M468 641a39 39 0 0 1-4 50L281 874a39 39 0 0 1-55 0L122 770a39 39 0 0 1 0-55l183-183a38 38 0 0 1 45-7l56-55c-45-38-113-35-156 7L67 660c-45 45-45 119 0 165l104 104c45 45 119 45 164 0l183-183c44-44 45-115 5-160l-55 55zm461-470L825 67c-46-45-120-45-165 0L477 250c-42 42-45 110-7 156l55-56c-3-7-4-14-4-18 0-6 1-18 11-27l183-183c10-10 21-12 27-12s18 2 28 12l104 104c10 9 11 21 11 27s-1 18-11 27L691 463a38 38 0 0 1-28 12c-5 0-14-1-22-8l-55 55c45 41 116 40 160-4l183-183c45-45 45-119 0-164zm-610 82c18 18 18 47 0 66a47 47 0 0 1-66 0l-92-92a46 46 0 0 1 0-66c18-18 48-18 66 0l92 92zm86-66c0 25-21 46-47 46s-46-21-46-46V56c0-25 20-46 46-46s47 21 47 46v131zM187 312c25 0 46 20 46 46s-21 47-46 47H57a46 46 0 1 1 0-93h130zm494 435a46 46 0 0 1 0-66c18-18 48-18 66 0l92 92c18 19 18 48 0 66a47 47 0 0 1-66 0l-92-92zm-86 67c0-26 21-47 47-47s46 21 46 47v130c0 25-20 46-46 46s-47-21-47-46V814zm218-126c-25 0-46-20-46-46s21-47 46-47h130c26 0 47 21 47 47s-21 46-46 46H813z"/></svg>');
					opacity: 0.5;
				}

				.device-tile-header .name {
					color: #808080;
				}
			}

			&:focus, &:hover {
				box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.05), 0 3px 15px rgba(0, 0, 0, 0.2);
				outline: none;
			}

			&:active {
				box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.15), 0 3px 15px rgba(0, 0, 0, 0.2);
				transform: translateY(2px);
				transition: all 0s ease;
			}
		}
	}
	.device-tile-header, .device-tile-content {
		padding: 15px;
	}

	.device-tile-header {
		.name {
			margin: 0;
			padding: 0 0 0.3em 0;
			color: #009688;
			font-size: 16px;
			text-transform: uppercase;
		}

		.mac-address {
			margin: 0;
		}
	}

	.device-tile-content {
		display: flex;
		flex-direction: column;
		flex: 1;
		border-top: 1px solid rgba(0, 0, 0, 0.05);
		background-color: rgba(0, 0, 0, 0.03);

		.description {
			flex: 1;
			opacity: 0.5;
			font-style: italic;
			padding-bottom: 0.5em;
		}
	}
}
