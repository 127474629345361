@import '../core/assets-url';

@mixin strio-options() {
	$options-shadow-color: rgba(0, 0, 0, 0.2);

	kn-options-container {
		display: block;
		box-shadow: 0 5px 10px $options-shadow-color;
		width: 100%;
		z-index: 110;

		.kn-options-container-viewport {
			max-height: 310px;
		}

		.kn-options-container-filter {
			display: flex;

			.clear {
				position: relative;
				font-size: 0;
				width: 40px;
				height: 40px;

				&::before, &::after {
					content: '';
					display: block;
					position: absolute;
					pointer-events: none;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					transition: opacity 0.3s ease;
				}

				&::before {
					mask: assets-url('img/search.svg') no-repeat center;
					-webkit-mask: assets-url('img/search.svg') no-repeat center; // sass-lint:disable-line no-vendor-prefixes
					opacity: 1;
				}

				&::after {
					mask: assets-url('img/clear.svg') no-repeat center;
					-webkit-mask: assets-url('img/clear.svg') no-repeat center; // sass-lint:disable-line no-vendor-prefixes
					opacity: 0;
				}

				&:hover {
					&::before {
						opacity: 0;
					}

					&::after {
						opacity: 1;
					}
				}
			}

			input {
				flex: 1;
				border: none;
				background: none;
				padding: 5px;
				outline: none;
			}
		}
	}

	kn-select, kn-input {
		&.focused kn-options-container .kn-options-container-viewport {
			z-index: 1000;
		}
	}

	kn-optgroup > strong {
		text-transform: uppercase;
	}

	kn-option {
		&:not(.disabled):hover {
			transition: none;
		}

		.kn-option-selector {
			transition: 0.3s color ease;
		}
	}
}

@mixin strio-options-theme($theme) {
	kn-options-container {
		background-color: map-get($theme, background);

		.kn-options-container-filter {
			background-color: blend(map-get($theme, background), map-get($theme, tone));

			.clear {
				&::before {
					background-color: map-get($theme, icon-disabled);
				}

				&::after {
					background-color: map-get($theme, icon);
				}
			}
		}

		.kn-options-container-hints {
			border-top: 1px dashed map-get($theme, divider);

			li {
				color: map-get($theme, text-secondary);

				em {
					color: map-get($theme, text);
				}
			}
		}
	}

	kn-optgroup > strong {
		color: map-get($theme, text-secondary);
		border-bottom: 1px solid map-get($theme, divider);
	}

	kn-option {
		&.marked {
			background-color: map-get($theme, marked);
		}

		&:not(.disabled):hover {
			background-color: map-get($theme, hover);
		}

		&.disabled {
			color: map-get($theme, text-disabled);
		}

		.kn-option-selector {
			color: map-get($theme, line);
		}

		&.selected .kn-option-selector {
			color: map-get($theme, primary);
		}
	}
}
