@import '../shared/flat-button';

@mixin strio-form-popover() {
	.form-layout .popover {
		position: relative;
		height: 34px;

		kn-menu-activator {
			cursor: pointer;
		}

		.side-action {
			@include strio-flat-button();
			height: 24px;
		}
	}

	@at-root kn-attached-portal .kn-form-layout-menu-content {
		z-index: 200;
		position: absolute;
		left: calc(100% - 490px);
		top: 40px;
		width: 500px;
		filter: drop-shadow(0 3px 12px rgba(0, 0, 0, 0.3));

		h3 {
			font-weight: normal;
			margin: 0;
			padding: 15px 15px 15px 20px;
		}

		.popover-close {
			@include strio-flat-button();
			position: absolute;
			top: 10px;
			right: 10px;
			height: 24px;
		}

		[hidden] {
			display: none;
		}

		&::before {
			position: absolute;
			left: calc(100% - 37px);
			top: -10px;
			content: '';
			display: block;
			width: 0;
			height: 0;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
		}
	}
}

@mixin strio-form-popover-theme($theme) {
	.form-layout .popover {
		.side-action {
			@include strio-flat-button-theme($theme);
		}
	}

	@at-root kn-attached-portal .kn-form-layout-menu-content {
		background-color: map-get($theme, background);

		h3 {
			border-bottom: 1px solid map-get($theme, divider);
		}

		.popover-close {
			@include strio-flat-button-theme($theme);
		}

		&::before {
			border-bottom: 10px solid map-get($theme, background);
		}
	}
}
