@import '../../projects/kn-datagrid/assets/scss/index';

@import 'datagrid-renderers';

app-cards-view, app-customers-view, app-suppliers-view, app-fuelings-view, app-groups-view {
	kn-datagrid thead {
		background-color: white;
		z-index: 1;
		position: sticky;
		top: 50px;
	}
}
