@mixin strio-radio() {
	kn-radio {
		input[type='radio'] {
			& + .kn-radio-box {
				&::before {
					border-radius: 50%;
				}

				&::after {
					border-radius: 50%;
					transition: 0.3s border-color ease;
				}
			}
		}

		.kn-radio-mark {
			transition: 0.2s border linear;
		}

		.kn-radio-box, label {
			transition: 0.3s color ease;
		}
	}
}

@mixin strio-radio-theme($theme) {
	kn-radio {
		input[type='radio'] {
			& + .kn-radio-box::after {
				border: 2px solid map-get($theme, input-divider);
				background-color: map-get($theme, undertone);
			}

			&:checked + .kn-radio-box {
				&::after {
					border-color: map-get($theme, primary);
				}

				.kn-radio-mark {
					border: 7px solid map-get($theme, primary);
				}
			}

			&:focus + .kn-radio-box::before {
				background-color: map-get($theme, focus);
			}
		}

		&:not(.disabled) input[type='radio']:not(:checked) {
			&:hover + .kn-radio-box::after, & + .kn-radio-box:hover::after {
				border-color: map-get($theme, input-divider-hover);
			}
		}

		&.disabled, &.readonly {
			label {
				color: map-get($theme, text-disabled);
			}

			input[type='radio']:not(:checked) + .kn-radio-box::after {
				background-color: transparent !important;
			}

			input[type='radio'] + .kn-radio-box {
				&::after, .kn-radio-mark {
					border-color: map-get($theme, input-line-disabled);
				}
			}
		}
	}

	kn-radio-group.ng-touched.ng-invalid kn-radio {
		.kn-radio-box::after, input[type='radio']:checked + .kn-radio-box .kn-radio-mark {
			border-color: map-get($theme, warn);
		}
	}
}
