@import '../shared/raised-button';

@mixin strio-header-liner() {
	$header-liner-button-shadow-color: rgba(0, 0, 0, 0.5);
	$header-liner-button-hover-shadow-color: rgba(0, 0, 0, 0.7);
	$header-liner-menu-shadow-color: rgba(0, 0, 0, 0.3);

	header.liner {
		height: 80px;
		display: flex;
		justify-content: space-between;
		background-size: 100% auto;
		background-repeat: no-repeat;
		background-attachment: fixed;

		& > div {
			display: flex;
			align-items: center;
		}

		.logo {
			outline: none;

			img {
				margin: 10px;
			}
		}

		button.main-action {
			@include strio-raised-button();
			margin: 0 15px;
			box-shadow: 0 2px 2px $header-liner-button-shadow-color;

			&:not([disabled]) {
				&:hover, &:focus {
					box-shadow: 0 2px 20px $header-liner-button-hover-shadow-color;
				}

				&:active {
					box-shadow: 0 2px 10px $header-liner-button-hover-shadow-color;
				}
			}
		}

		kn-menu {
			z-index: 2;
			position: relative;

			&.expanded {
				box-shadow: 0 1px 2px $header-liner-menu-shadow-color;
			}

			kn-menu-activator {
				display: flex;
				padding: 16px;
				transition: all 0.3s ease;
				cursor: pointer;
				outline: none;
			}

			kn-menu-content {
				position: absolute;
				min-width: 100%;
				left: 0;
				margin-top: 0;
				box-shadow: 0 1px 2px $header-liner-menu-shadow-color;
				z-index: 100;

				&[hidden] {
					opacity: 0;
					margin-top: -10px;
					transition: all 0.3s ease;
				}

				ul {
					padding: 0;
					margin: 0;
					list-style-type: none;

					li {
						padding: 0;
						margin: 0;
					}
				}

				a {
					padding: 10px;
					display: block;
					white-space: nowrap;
					transition: all 0.3s ease;

					&:hover {
						text-decoration: none;
					}
				}
			}
		}
	}

	@at-root kn-attached-portal .header-liner.kn-menu-content {
		box-shadow: 0 1px 2px $header-liner-menu-shadow-color;
		z-index: 100;

		&[hidden] {
			opacity: 0;
			margin-top: -10px;
			transition: all 0.3s ease;
		}

		ul {
			padding: 0;
			margin: 0;
			list-style-type: none;

			li {
				padding: 0;
				margin: 0;
			}
		}

		a {
			padding: 10px;
			display: block;
			white-space: nowrap;
			transition: all 0.3s ease;

			&:hover {
				text-decoration: none;
			}
		}
	}
}

@mixin strio-header-liner-theme($theme) {
	header.liner {
		background-color: map-get($theme, primary);
		background-image: linear-gradient(to bottom, rgba(map-get($theme, foreground), 0.25), rgba(map-get($theme, foreground), 0.4) 80px); // sass-lint:disable-line no-color-literals

		.logo:hover, .logo:focus {
			background-color: rgba(map-get($theme, background), 0.05); // sass-lint:disable-line no-color-literals
		}

		button.main-action {
			@include strio-raised-button-theme($theme);
			background-color: map-get($theme, accent);

			&:not([disabled]) {
				&:hover, &:focus, &:active {
					background-color: mix(map-get($theme, accent), map-get($theme, background), 70%);
				}

				&:active {
					background-color: mix(map-get($theme, accent), map-get($theme, background), 80%);
				}
			}
		}

		kn-menu {
			color: rgba(map-get($theme, background), 0.5); // sass-lint:disable-line no-color-literals
			z-index: 10;

			&.expanded {
				background-color: map-get($theme, primary);

				kn-menu-content {
					border-top: 1px solid rgba(map-get($theme, background), 0.1); // sass-lint:disable-line no-color-literals
				}
			}

			kn-menu-activator:hover, kn-menu-activator:focus {
				background-color: rgba(map-get($theme, background), 0.1); // sass-lint:disable-line no-color-literals
				color: map-get($theme, background);
			}

			kn-menu-content {
				background-color: map-get($theme, primary);

				a {
					color: rgba(map-get($theme, background), 0.5); // sass-lint:disable-line no-color-literals

					&:hover {
						background-color: rgba(map-get($theme, background), 0.1); // sass-lint:disable-line no-color-literals
						color: map-get($theme, background);
					}
				}
			}
		}
	}

	@at-root kn-attached-portal .header-liner.kn-menu-content {
		border-top: 1px solid rgba(map-get($theme, background), 0.1); // sass-lint:disable-line no-color-literals
		background-color: map-get($theme, primary);

		a {
			color: rgba(map-get($theme, background), 0.5); // sass-lint:disable-line no-color-literals

			&:hover {
				background-color: rgba(map-get($theme, background), 0.1); // sass-lint:disable-line no-color-literals
				color: map-get($theme, background);
			}
		}
	}
}
