@font-face {
	font-family: 'Exo 2';
	font-style: normal;
	font-weight: 300;
	src: local('Exo 2 Light'), local('Exo2-Light'), url('../fonts/exo2-300.woff') format('woff');
}

@font-face {
	font-family: 'Exo 2';
	font-style: normal;
	font-weight: 400;
	src: local('Exo 2'), local('Exo2-Regular'), url('../fonts/exo2-400.woff') format('woff');
}

@font-face {
	font-family: 'Exo 2';
	font-style: normal;
	font-weight: 700;
	src: local('Exo 2 Bold'), local('Exo2-Bold'), url('../fonts/exo2-700.woff') format('woff');
}

@font-face {
	font-family: 'Exo 2';
	font-style: italic;
	font-weight: 300;
	src: local('Exo 2 Light Italic'), local('Exo2-LightItalic'), url('../fonts/exo2-300it.woff') format('woff');
}

@font-face {
	font-family: 'Exo 2';
	font-style: italic;
	font-weight: 400;
	src: local('Exo 2 Italic'), local('Exo2-Italic'), url('../fonts/exo2-400it.woff') format('woff');
}

@font-face {
	font-family: 'Exo 2';
	font-style: italic;
	font-weight: 700;
	src: local('Exo 2 Bold Italic'), local('Exo2-BoldItalic'), url('../fonts/exo2-700it.woff') format('woff');
}
