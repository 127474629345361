@charset "UTF-8";
/* BASICS */
.CodeMirror {
  /* Set height, width, borders, and global font properties here */
  font-family: monospace;
  height: 300px;
  color: black;
  direction: ltr;
}

/* PADDING */
.CodeMirror-lines {
  padding: 4px 0;
  /* Vertical padding around content */
}

.CodeMirror pre.CodeMirror-line,
.CodeMirror pre.CodeMirror-line-like {
  padding: 0 4px;
  /* Horizontal padding of content */
}

.CodeMirror-scrollbar-filler, .CodeMirror-gutter-filler {
  background-color: white;
  /* The little square between H and V scrollbars */
}

/* GUTTER */
.CodeMirror-gutters {
  border-right: 1px solid #ddd;
  background-color: #f7f7f7;
  white-space: nowrap;
}

.CodeMirror-linenumber {
  padding: 0 3px 0 5px;
  min-width: 20px;
  text-align: right;
  color: #999;
  white-space: nowrap;
}

.CodeMirror-guttermarker {
  color: black;
}

.CodeMirror-guttermarker-subtle {
  color: #999;
}

/* CURSOR */
.CodeMirror-cursor {
  border-left: 1px solid black;
  border-right: none;
  width: 0;
}

/* Shown when moving in bi-directional text */
.CodeMirror div.CodeMirror-secondarycursor {
  border-left: 1px solid silver;
}

.cm-fat-cursor .CodeMirror-cursor {
  width: auto;
  border: 0 !important;
  background: #7e7;
}

.cm-fat-cursor div.CodeMirror-cursors {
  z-index: 1;
}

.cm-fat-cursor .CodeMirror-line::selection,
.cm-fat-cursor .CodeMirror-line > span::selection,
.cm-fat-cursor .CodeMirror-line > span > span::selection {
  background: transparent;
}

.cm-fat-cursor .CodeMirror-line::-moz-selection,
.cm-fat-cursor .CodeMirror-line > span::-moz-selection,
.cm-fat-cursor .CodeMirror-line > span > span::-moz-selection {
  background: transparent;
}

.cm-fat-cursor {
  caret-color: transparent;
}

@-moz-keyframes blink {
  50% {
    background-color: transparent;
  }
}
@-webkit-keyframes blink {
  50% {
    background-color: transparent;
  }
}
@keyframes blink {
  50% {
    background-color: transparent;
  }
}
/* Can style cursor different in overwrite (non-insert) mode */
.cm-tab {
  display: inline-block;
  text-decoration: inherit;
}

.CodeMirror-rulers {
  position: absolute;
  left: 0;
  right: 0;
  top: -50px;
  bottom: 0;
  overflow: hidden;
}

.CodeMirror-ruler {
  border-left: 1px solid #ccc;
  top: 0;
  bottom: 0;
  position: absolute;
}

/* DEFAULT THEME */
.cm-s-default .cm-header {
  color: blue;
}

.cm-s-default .cm-quote {
  color: #090;
}

.cm-negative {
  color: #d44;
}

.cm-positive {
  color: #292;
}

.cm-header, .cm-strong {
  font-weight: bold;
}

.cm-em {
  font-style: italic;
}

.cm-link {
  text-decoration: underline;
}

.cm-strikethrough {
  text-decoration: line-through;
}

.cm-s-default .cm-keyword {
  color: #708;
}

.cm-s-default .cm-atom {
  color: #219;
}

.cm-s-default .cm-number {
  color: #164;
}

.cm-s-default .cm-def {
  color: #00f;
}

.cm-s-default .cm-variable-2 {
  color: #05a;
}

.cm-s-default .cm-variable-3, .cm-s-default .cm-type {
  color: #085;
}

.cm-s-default .cm-comment {
  color: #a50;
}

.cm-s-default .cm-string {
  color: #a11;
}

.cm-s-default .cm-string-2 {
  color: #f50;
}

.cm-s-default .cm-meta {
  color: #555;
}

.cm-s-default .cm-qualifier {
  color: #555;
}

.cm-s-default .cm-builtin {
  color: #30a;
}

.cm-s-default .cm-bracket {
  color: #997;
}

.cm-s-default .cm-tag {
  color: #170;
}

.cm-s-default .cm-attribute {
  color: #00c;
}

.cm-s-default .cm-hr {
  color: #999;
}

.cm-s-default .cm-link {
  color: #00c;
}

.cm-s-default .cm-error {
  color: #f00;
}

.cm-invalidchar {
  color: #f00;
}

.CodeMirror-composing {
  border-bottom: 2px solid;
}

/* Default styles for common addons */
div.CodeMirror span.CodeMirror-matchingbracket {
  color: #0b0;
}

div.CodeMirror span.CodeMirror-nonmatchingbracket {
  color: #a22;
}

.CodeMirror-matchingtag {
  background: rgba(255, 150, 0, 0.3);
}

.CodeMirror-activeline-background {
  background: #e8f2ff;
}

/* STOP */
/* The rest of this file contains styles related to the mechanics of
   the editor. You probably shouldn't touch them. */
.CodeMirror {
  position: relative;
  overflow: hidden;
  background: white;
}

.CodeMirror-scroll {
  overflow: scroll !important;
  /* Things will break if this is overridden */
  /* 50px is the magic margin used to hide the element's real scrollbars */
  /* See overflow: hidden in .CodeMirror */
  margin-bottom: -50px;
  margin-right: -50px;
  padding-bottom: 50px;
  height: 100%;
  outline: none;
  /* Prevent dragging from highlighting the element */
  position: relative;
  z-index: 0;
}

.CodeMirror-sizer {
  position: relative;
  border-right: 50px solid transparent;
}

/* The fake, visible scrollbars. Used to force redraw during scrolling
   before actual scrolling happens, thus preventing shaking and
   flickering artifacts. */
.CodeMirror-vscrollbar, .CodeMirror-hscrollbar, .CodeMirror-scrollbar-filler, .CodeMirror-gutter-filler {
  position: absolute;
  z-index: 6;
  display: none;
  outline: none;
}

.CodeMirror-vscrollbar {
  right: 0;
  top: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

.CodeMirror-hscrollbar {
  bottom: 0;
  left: 0;
  overflow-y: hidden;
  overflow-x: scroll;
}

.CodeMirror-scrollbar-filler {
  right: 0;
  bottom: 0;
}

.CodeMirror-gutter-filler {
  left: 0;
  bottom: 0;
}

.CodeMirror-gutters {
  position: absolute;
  left: 0;
  top: 0;
  min-height: 100%;
  z-index: 3;
}

.CodeMirror-gutter {
  white-space: normal;
  height: 100%;
  display: inline-block;
  vertical-align: top;
  margin-bottom: -50px;
}

.CodeMirror-gutter-wrapper {
  position: absolute;
  z-index: 4;
  background: none !important;
  border: none !important;
}

.CodeMirror-gutter-background {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 4;
}

.CodeMirror-gutter-elt {
  position: absolute;
  cursor: default;
  z-index: 4;
}

.CodeMirror-gutter-wrapper ::selection {
  background-color: transparent;
}

.CodeMirror-gutter-wrapper ::-moz-selection {
  background-color: transparent;
}

.CodeMirror-lines {
  cursor: text;
  min-height: 1px;
  /* prevents collapsing before first draw */
}

.CodeMirror pre.CodeMirror-line,
.CodeMirror pre.CodeMirror-line-like {
  /* Reset some styles that the rest of the page might have set */
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  border-width: 0;
  background: transparent;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  white-space: pre;
  word-wrap: normal;
  line-height: inherit;
  color: inherit;
  z-index: 2;
  position: relative;
  overflow: visible;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-variant-ligatures: contextual;
  font-variant-ligatures: contextual;
}

.CodeMirror-wrap pre.CodeMirror-line,
.CodeMirror-wrap pre.CodeMirror-line-like {
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: normal;
}

.CodeMirror-linebackground {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
}

.CodeMirror-linewidget {
  position: relative;
  z-index: 2;
  padding: 0.1px;
  /* Force widget margins to stay inside of the container */
}

.CodeMirror-rtl pre {
  direction: rtl;
}

.CodeMirror-code {
  outline: none;
}

/* Force content-box sizing for the elements where we expect it */
.CodeMirror-scroll,
.CodeMirror-sizer,
.CodeMirror-gutter,
.CodeMirror-gutters,
.CodeMirror-linenumber {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.CodeMirror-measure {
  position: absolute;
  width: 100%;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.CodeMirror-cursor {
  position: absolute;
  pointer-events: none;
}

.CodeMirror-measure pre {
  position: static;
}

div.CodeMirror-cursors {
  visibility: hidden;
  position: relative;
  z-index: 3;
}

div.CodeMirror-dragcursors {
  visibility: visible;
}

.CodeMirror-focused div.CodeMirror-cursors {
  visibility: visible;
}

.CodeMirror-selected {
  background: #d9d9d9;
}

.CodeMirror-focused .CodeMirror-selected {
  background: #d7d4f0;
}

.CodeMirror-crosshair {
  cursor: crosshair;
}

.CodeMirror-line::selection, .CodeMirror-line > span::selection, .CodeMirror-line > span > span::selection {
  background: #d7d4f0;
}

.CodeMirror-line::-moz-selection, .CodeMirror-line > span::-moz-selection, .CodeMirror-line > span > span::-moz-selection {
  background: #d7d4f0;
}

.cm-searching {
  background-color: #ffa;
  background-color: rgba(255, 255, 0, 0.4);
}

/* Used to force a border model for a node */
.cm-force-border {
  padding-right: 0.1px;
}

@media print {
  /* Hide the cursor when printing */
  .CodeMirror div.CodeMirror-cursors {
    visibility: hidden;
  }
}
/* See issue #2901 */
.cm-tab-wrap-hack:after {
  content: "";
}

/* Help users use markselection to safely style text background */
span.CodeMirror-selectedtext {
  background: none;
}

.CodeMirror-foldmarker {
  color: blue;
  text-shadow: #b9f 1px 1px 2px, #b9f -1px -1px 2px, #b9f 1px -1px 2px, #b9f -1px 1px 2px;
  font-family: arial;
  line-height: 0.3;
  cursor: pointer;
}

.CodeMirror-foldgutter {
  width: 0.7em;
}

.CodeMirror-foldgutter-open,
.CodeMirror-foldgutter-folded {
  cursor: pointer;
}

.CodeMirror-foldgutter-open:after {
  content: "▾";
}

.CodeMirror-foldgutter-folded:after {
  content: "▸";
}

.CodeMirror-hints {
  position: absolute;
  z-index: 10;
  overflow: hidden;
  list-style: none;
  margin: 0;
  padding: 2px;
  -webkit-box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  border: 1px solid silver;
  background: white;
  font-size: 90%;
  font-family: monospace;
  max-height: 20em;
  overflow-y: auto;
  box-sizing: border-box;
}

.CodeMirror-hint {
  margin: 0;
  padding: 0 4px;
  border-radius: 2px;
  white-space: pre;
  color: black;
  cursor: pointer;
}

li.CodeMirror-hint-active {
  background: #08f;
  color: white;
}

/* stylelint-disable no-descending-specificity */
.CodeMirror-gutters {
  background-color: #e8e8ec;
  border-right: solid 1px #e0e0e8;
  height: 100%;
  box-shadow: 0 0 5px #e0e0e8;
}

.CodeMirror-foldgutter {
  width: 1em !important;
}

.CodeMirror-linenumbers {
  padding-right: 5px;
  width: 3.7em !important;
}

.CodeMirror-linenumber {
  color: #b9b6d2;
  font-size: 1em;
  padding: 0 5px 0 0;
  text-shadow: 0 0 1px #ffffff;
}

.CodeMirror-foldgutter-open,
.CodeMirror-foldgutter-folded {
  position: relative;
  font-family: monospace;
  font-size: 24px;
  height: 1em;
}

.CodeMirror-foldgutter-folded {
  line-height: 1.7em;
}

.CodeMirror-foldgutter-open::after {
  content: "▾" !important;
  position: absolute;
  top: -2px;
  color: #b9b6d2;
}

.CodeMirror-activeline .CodeMirror-foldgutter-open::after {
  color: #787a82 !important;
}

.CodeMirror-foldgutter-folded::after {
  content: "▾" !important;
  position: absolute;
  top: -8px;
  color: #4a4543;
  transform: rotate(270deg);
}

.CodeMirror.over-gutter .CodeMirror-foldgutter-open::after,
.CodeMirror-activeline .CodeMirror-foldgutter-open::after {
  color: #b9b6d2;
}

.CodeMirror-foldmarker {
  position: relative;
  background-color: #00bcd4;
  color: transparent !important;
  padding: 0 0.5em;
  text-shadow: none !important;
  margin: 0 0.5em;
  border-radius: 3px;
}

.CodeMirror-foldmarker::after {
  position: absolute;
  content: "↔";
  color: white;
  top: 0.4em;
  left: 0.5em;
  font-family: "arial";
}

.CodeMirror-scroll {
  color: #4a4543;
  line-height: 1.55em;
}

.CodeMirror-cursor {
  border-left: 1px solid #39464e;
  height: 1em;
}

.CodeMirror-selected {
  background-color: #d7d4f0;
  border: 0;
  outline: none;
}

.CodeMirror-activeline-background {
  background: rgba(216, 144, 56, 0.1);
}

.CodeMirror-focused .cm-matchhighlight {
  border-width: 0 !important;
}

.cm-em {
  font-style: italic;
}

.cm-link {
  text-decoration: underline;
}

.cm-strikethrough {
  text-decoration: line-through;
}

.cm-header {
  color: #4695ee;
}

.cm-positive,
.cm-quote {
  color: #249d82;
}

.cm-negative {
  color: #ff4d4d;
}

.cm-keyword {
  color: #a151d2;
}

.cm-comment {
  color: #a0a1a7;
}

.cm-property {
  color: #39464e;
}

.cm-string {
  color: #249d82;
}

.cm-number,
.cm-string-2 {
  color: #ed5c65;
}

.cm-tag.cm-bracket {
  color: #000000;
}

.cm-atom {
  color: #221199;
}

.cm-keyword,
.cm-quote {
  color: #a151d2;
}

.cm-attribute,
.cm-builtin,
.cm-def,
.cm-qualifier,
.cm-keyword + span[class^=cm-m-javascript] + .cm-variable,
.cm-dk-whitespace-leading-space + .cm-variable,
.cm-m-javascript + .cm-variable {
  color: #f18c16;
}

.cm-tag,
.cm-variable,
.cm-variable-2,
.cm-variable-3,
.cm-operator + span[class^=cm-m-javascript] + .cm-variable {
  color: #4695ee;
}

.cm-m-xml.cm-tag,
.cm-m-xml.cm-keyword {
  color: #000000;
  text-shadow: 0 0 1px #ffffff;
}

.cm-m-xml.cm-tag ~ .cm-m-xml.cm-variable {
  color: #ed5c65;
  font-style: italic;
  font-weight: bold;
  text-shadow: 0 0 1px #ffffff;
}

.cm-m-xml.cm-bracket + .cm-m-xml.cm-tag {
  color: #4695ee;
  font-weight: normal;
}

.cm-m-xml.cm-bracket {
  color: #4a4543;
  font-weight: normal;
}

.cm-m-xml.cm-tag {
  color: #4a4543;
}

.cm-m-xml.cm-keyword {
  color: #000000;
  font-weight: bold;
}

.cm-atom,
.cm-attribute,
.cm-builtin,
.cm-comment,
.cm-def,
.cm-keyword,
.cm-number,
.cm-property,
.cm-qualifier,
.cm-quote,
.cm-tag,
.cm-variable,
.cm-variable-2,
.cm-variable-3,
.cm-string,
.cm-string-2 {
  text-shadow: 0 1px 1px #ffffff;
}

.CodeMirror-focused .CodeMirror-activeline .CodeMirror-gutter-elt {
  color: #4a4543;
  text-shadow: 0 0 1px #ffffff;
}

.CodeMirror-nonmatchingbracket,
.CodeMirror-matchingbracket {
  background: rgba(255, 150, 0, 0.3);
  color: #000000 !important;
  padding: 3px 0;
}

.cm-m-xml.CodeMirror-matchingtag {
  background: transparent;
}