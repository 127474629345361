@import '../shared/field';
@import '../shared/native-field';

@mixin strio-input() {
	@include strio-interpolated-field(input);

	kn-input input {
		min-height: 24px;

		// sass-lint:disable no-vendor-prefixes
		&:-webkit-autofill {
			@-webkit-keyframes kill-autofill { to { background: transparent; } }
			-webkit-animation-name: kill-autofill;
			-webkit-animation-fill-mode: both;
		}
		// sass-lint:enable no-vendor-prefixes
	}
}

@mixin strio-input-theme($theme) {
	@include strio-interpolated-field-theme(input, $theme);
	@include strio-interpolated-native-field-theme(input, $theme);
}
