.pc-day-counts .ct-bar {
	stroke-width: 3.5%;
}

.pc-month-counts .ct-bar {
	stroke-width: 2.5%;
}

.pc-year-counts .ct-bar {
	stroke-width: 4.7%;
}

.pc-days-in-week-counts .ct-bar {
	stroke-width: 9.5%;
}

.pc-hours-in-day-counts .ct-bar {
	stroke-width: 3.5%;
}

.float-right kn-select, .float-right kn-date-input {
	position: absolute;
	right: 10px;
	top: 10px;
	width: 200px;
	color: rgba(0, 0, 0, 0.8);

	label {
		display: none;
	}
}

.graph-container {
	height: 200px;
	margin: 20px 0 5px 0;
}

.no-data-placeholder {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 30px;
	opacity: 0.5;

	i {
		font-size: 32px;
		margin-bottom: 5px;
	}
}

.legend {
	display: flex;
	gap: 20px;
	margin: 0 15px 15px auto;

	& > div {
		display: flex;
		align-items: center;
		gap: 5px;

		&::before {
			content: '';
			width: 18px;
			height: 18px;
		}

		&.in::before {
			background-color: #f4c63d;
		}

		&.out::before {
			background-color: #f05b4f;
		}

		&.inout::before {
			background-color: #d70206;
		}
	}
}
