@keyframes indeterminate {
	0% {
		left: 0%;
		right: 100%;
	}
	30% {
		left: 0%;
		right: 50%;
	}
	80% {
		left: 70%;
		right: 0%;
	}
	100% {
		left: 100%;
		right: 0%;
	}
}
