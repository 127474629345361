@import '../shared/flat-button';

@mixin strio-grid-panel() {
	$grid-panel-shadow-color: rgba(0, 0, 0, 0.5);

	kn-sticky.sticked > .grid-panel {
		box-shadow: 0 0 10px $grid-panel-shadow-color;
	}

	.grid-panel {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		padding: 8px;

		.selector {
			width: 200px;
		}

		.actions, kn-grid-actions {
			display: flex;
			flex-grow: 1;
			font-size: 0;
		}

		.widgets {
			display: flex;

			& > *:not(:last-child) {
				margin-right: 10px;
			}
		}

		.selector a, .actions a, kn-grid-actions a {
			padding: 5px 7px;
			opacity: 0.7;
			transition: background-color 0.5s ease;
			display: inline-block;
			line-height: 24px;
			border-radius: 2px;

			i {
				vertical-align: top;
				display: inline-block;
				width: 24px;
				height: 24px;
				overflow: hidden;
			}

			&:hover {
				opacity: 1;

				i {
					opacity: 1;
				}
			}

			&:hover, &:focus, &:active {
				text-decoration: none;
			}
		}
	}
}

@mixin strio-grid-panel-theme($theme) {
	.grid-panel {
		background-color: map-get($theme, accent);

		.selector a, .actions a, kn-grid-actions a {
			color: rgba(map-get($theme, background), 0.7); // sass-lint:disable-line no-color-literals

			&:hover {
				color: map-get($theme, background);
				background-color: map-get($theme, grid-menu-hover);
			}
		}
	}
}
