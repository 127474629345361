@mixin strio-modal() {
	$modal-button-shadow-color: rgba(0, 0, 0, 0.2);

	kn-modal {
		.overlay {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1000;
		}

		&.confirmation-dialog .overlay {
			display: flex;
			align-items: center;
			justify-content: center;

			&.kn-enter, &.kn-leave {
				transition: 0.3s opacity ease;

				.container {
					transition: 0.3s transform ease, 0.3s margin-top ease-out;
				}
			}

			&.kn-enter {
				opacity: 0;

				.container {
					transform: perspective(1000px) rotate3d(1, 0, 0, 60deg);
				}
			}

			&.kn-leave, &.kn-enter.kn-enter-active {
				opacity: 1;

				.container {
					transform: scale(1);
				}
			}

			&.kn-leave.kn-leave-active {
				opacity: 0;

				.container {
					transform: scale(0.8);
					margin-top: 100px;
				}
			}

			.container {
				overflow-y: auto;
				min-width: 300px;
				max-width: 750px;
				min-height: 200px;
				display: flex;
				flex-direction: column;
			}

			.content {
				padding: 15px 20px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				flex: 1;

				span {
					white-space: pre-line;
					text-align: center;
					margin-top: 10px;
				}
			}

			.buttons {
				display: flex;

				button {
					position: relative;
					flex: 1;
					padding: 15px;
					min-width: 150px;
					border: none;
					background: none;
					cursor: pointer;
					transition: color 0.5s ease, background-color 0.5s ease;

					&::before {
						content: '';
						width: 60%;
						height: 3px;
						position: absolute;
						display: block;
						top: 0;
						left: 20%;
						opacity: 0.3;
					}

					&:hover::before {
						opacity: 1;
					}

					&:hover, &:focus {
						transition: color 0.2s ease, background-color 0.2s ease;
					}

					&:active {
						box-shadow: 0 2px 2px $modal-button-shadow-color inset;
						padding: 16px 15px 14px 15px;
					}
				}
			}
		}
	}
}

@mixin strio-modal-theme($theme) {
	$modal-foreground-color: white;
	$modal-overlay-color: rgba(48, 48, 48, 0.9);
	$modal-container-color: rgba(40, 40, 40, 0.7);
	$modal-button-color: white;
	$modal-button-primary-color: rgb(64, 128, 255);
	$modal-button-danger-color: rgb(255, 64, 72);
	$modal-button-background-color: rgba(0, 0, 0, 0.2);
	$modal-button-active-text-color: rgba(0, 0, 0, 0.8);

	kn-modal {
		&.confirmation-dialog .overlay {
			background-color: $modal-overlay-color;

			.container {
				background-color: $modal-container-color;
			}

			.content {
				color: $modal-foreground-color;
			}

			.buttons {
				background-color: $modal-button-background-color;

				button {
					color: rgba($modal-foreground-color, 0.5);

					&.primary {
						color: rgba($modal-button-primary-color, 0.8);

						&::before {
							background-color: $modal-button-primary-color;
						}

						&:hover, &:focus {
							background-color: rgba($modal-button-primary-color, 0.7);
							color: $modal-button-active-text-color;
						}
					}

					&.danger {
						color: rgba($modal-button-danger-color, 0.8);

						&::before {
							background-color: $modal-button-danger-color;
						}

						&:hover, &:focus {
							background-color: rgba($modal-button-danger-color, 0.7);
							color: $modal-button-active-text-color;
						}
					}

					&::before {
						background-color: $modal-button-color;
					}

					&:hover, &:focus {
						background-color: rgba($modal-button-color, 0.7);
						color: $modal-button-active-text-color;
					}
				}
			}
		}
	}
}
