@import 'colors';

kn-datagrid kn-numbered-selector {
	height: 24px;
	margin: -10px;
	padding: 10px;

	i.material-icons {
		width: 24px;
		height: 24px;
		font-size: 24px;
	}

	.row-number, .selector {
		position: absolute;
		top: 10px;
		bottom: 10px;
		left: 10px;
		right: 10px;
	}

	.row-number {
		opacity: 1;
		color: $text-hint-color;
		line-height: 24px;
		text-align: right;
		transition: 0.3s opacity ease;
	}

	.selector {
		display: block;
		right: 20px;
		opacity: 0;
		color: $primary-color;
		text-align: center;
		transition: 0.3s opacity ease, 0.3s right ease;
	}

	&.force-display-selector, &:hover {
		.row-number {
			display: block;
			opacity: 0;
		}

		.selector {
			right: 10px;
			opacity: 1;
		}
	}
}
