@import '../shared/blend';

@mixin strio-timeline() {
	kn-timeline {
		position: relative;

		&::before, &::after {
			content: '';
			position: absolute;
			display: block;
			top: 0;
			width: 20%;
			height: 100%;
			z-index: 2;
			pointer-events: none;
		}

		&::before {
			left: 0;
		}

		&::after {
			right: 0;
		}

		.time-viewport {
			overflow: hidden;
		}

		.time-container {
			display: flex;
			margin: 100px 0 50px 0;

			& > span {
				flex-shrink: 0;
			}
		}

		.time-segment {
			font-size: 0;
			height: 3px;
			width: calc(50% - 2px);
		}

		.time-mark {
			position: relative;
			width: 3px;
			height: 3px;

			a {
				position: absolute;
				width: 13px;
				height: 13px;
				top: -5px;
				left: -5px;
				border-radius: 50%;
				z-index: 1;
				outline: none;

				&.selected::after {
					content: '';
					position: absolute;
					display: block;
					width: 23px;
					height: 23px;
					left: -7px;
					top: -7px;
					border-radius: 50%;
				}

				&::before {
					content: '';
					position: absolute;
					display: block;
					width: 53px;
					height: 53px;
					top: -20px;
					left: -20px;
					border-radius: 50%;
					z-index: -1;
				}

				.date {
					position: absolute;
					display: block;
					white-space: nowrap;
					text-align: center;
					left: 7px;
					top: -68px;
					font-weight: bold;
					transform: translateX(-50%);
				}

				.year {
					display: block;
					font-weight: normal;
				}

				i.material-icons {
					top: 20px;
					position: absolute;
					left: 1px;
				}
			}

			&.pin a {
				width: 9px;
				height: 9px;
				top: -7px;
				left: -7px;

				&::before {
					top: -22px;
					left: -22px;
				}

				&.selected::after {
					left: -9px;
					top: -9px;
				}

				.date {
					left: 5px;
					top: -70px;
				}
			}

			&.today:not(.selectable)::after {
				content: '';
				position: absolute;
				display: block;
				width: 3px;
				height: 15px;
				left: 0;
				top: -6px;
			}

			&.today::before {
				content: '';
				position: absolute;
				display: block;
				width: 1px;
				height: 63px;
				left: 1px;
				top: -30px;
				opacity: 0.5;
			}

			&.today:not(.selectable)::after {
				content: '';
				position: absolute;
				display: block;
				width: 3px;
				height: 15px;
				left: 0;
				top: -6px;
			}
		}

		.time-mark.today a .date {
			top: -60px;
		}

		kn-datepicker .viewport {
			top: -20px;
		}

		kn-datepicker.calendar-mark .viewport {
			top: 5px;
		}
	}
}

@mixin strio-timeline-theme($theme) {
	$timeline-selected-color: rgb(13, 194, 43);
	$timeline-past-color: rgb(24, 96, 216);
	$timeline-future-color: rgb(128, 40, 216);
	$timeline-point-color: white;

	kn-timeline {
		&::before {
			background: linear-gradient(90deg, map-get($theme, page-background), transparent);
		}

		&::after {
			background: linear-gradient(270deg, map-get($theme, page-background), transparent);
		}

		.time-segment {
			background-color: $timeline-past-color;

			&.future {
				background-color: $timeline-future-color;
			}

			&.selected {
				background-color: $timeline-selected-color;
			}
		}

		.time-mark {
			> .calendar-mark {
				position: relative;
				top: 25px;
				left: -5px;

				&.today {
					left: -6px;
				}

				&.pin {
					left: -3px;
				}

				i.material-icons {
					color: map-get($theme, icon);
				}
			}

			> a {
				background-color: $timeline-past-color;

				&.selected {
					background-color: $timeline-past-color;

					.date {
						color: map-get($theme, text);
					}

					&::after {
						border: 2px solid $timeline-past-color;
					}
				}

				&:focus::before {
					background-color: map-get($theme, em);
				}

				.date {
					color: map-get($theme, text-secondary);
				}
			}

			&.pin > a {
				background-color: $timeline-point-color;
				border: 4px solid $timeline-past-color;
			}

			&.today:not(.selectable)::after {
				background-color: blend(map-get($theme, page-background), map-get($theme, icon));
			}

			&.today::before {
				background: linear-gradient(0deg, transparent, map-get($theme, icon), transparent);
			}

			&.today:not(.selectable)::after {
				background-color: blend(map-get($theme, page-background), map-get($theme, icon));
			}
		}

		.time-mark {
			&.future {
				> a {
					background-color: $timeline-future-color;

					&.selected::after {
						border-color: $timeline-future-color;
					}
				}

				&.pin > a {
					background-color: $timeline-point-color;
					border-color: $timeline-future-color;
				}
			}

			&.today {
				> a {
					background-color: blend(map-get($theme, page-background), map-get($theme, icon));

					&.selected::after {
						border-color: blend(map-get($theme, page-background), map-get($theme, icon));
					}

					.date {
						top: -60px;
					}
				}

				&.pin > a {
					background-color: $timeline-point-color;
					border-color: blend(map-get($theme, page-background), map-get($theme, icon));
				}
			}
		}
	}
}
