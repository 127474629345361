@mixin strio-datagrid-renderers() {
	$datagrid-renderer-tag-color: rgb(255, 255, 255);
	$datagrid-renderer-highlight-color: rgb(128, 32, 32);
	$datagrid-renderer-note-color: rgb(128, 128, 128);
	$datagrid-renderer-user-ownership-separator-color: rgba(0, 0, 0, 0.3);
	$datagrid-renderer-user-ownership-background-color: rgba(0, 0, 0, 0.1);
	$datagrid-renderer-user-ownership-color: rgb(0, 0, 0);

	kn-datagrid {
		tr td {
			&.strong {
				font-weight: bold;
			}

			&.highlight {
				color: $datagrid-renderer-highlight-color;
			}

			&.note {
				color: $datagrid-renderer-note-color;
				font-size: 0.8em;
			}

			&.overflow {
				overflow: visible;
			}
		}

		kn-cell-renderer {
			span.user-database-tag, span.user-role-tag {
				color: $datagrid-renderer-tag-color;
				padding: 4px 7px;
				margin: 0 5px 0 0;
				border-radius: 2px;
			}

			span.user-ownership {
				color: $datagrid-renderer-user-ownership-separator-color;

				& > span {
					color: $datagrid-renderer-user-ownership-color;
					background-color: $datagrid-renderer-user-ownership-background-color;
					padding: 4px;
					margin: 0 4px;
					border-radius: 2px;
				}
			}
		}
	}
}

@mixin strio-datagrid-renderers-theme($theme) {
	// sass-lint:disable-block no-empty-rulesets
}
