@mixin strio-spinner() {
	kn-spinner {
		position: absolute;
		margin: 0 auto;
		width: 36px;

		&:before { // sass-lint:disable-line pseudo-element
			content: '';
			display: block;
			padding-top: 100%;
		}

		.circular {
			animation: strio-spinner-rotate 2s linear infinite;
			height: 100%;
			transform-origin: center center;
			width: 100%;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;
		}

		.path {
			stroke-dasharray: 1, 200;
			stroke-dashoffset: 0;
			animation: strio-spinner-dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
			stroke-linecap: square;
			stroke-width: 8px;
		}

		@keyframes strio-spinner-rotate {
			100% {
				transform: rotate(360deg);
			}
		}

		@keyframes strio-spinner-dash {
			0% {
				stroke-dasharray: 1, 200;
				stroke-dashoffset: 0;
			}

			50% {
				stroke-dasharray: 89, 200;
				stroke-dashoffset: -35px;
			}

			100% {
				stroke-dasharray: 89, 200;
				stroke-dashoffset: -124px;
			}
		}
	}
}

@mixin strio-spinner-theme($theme) {
	kn-spinner {
		@include kn-spinner-color(map-get($theme, primary));
	}
}

@mixin kn-spinner-color($color) {
	$kn-spinner-color-n: 0 !default !global;
	$kn-spinner-color-n: $kn-spinner-color-n + 1 !global;
	$name: unquote('strio-spinner-color-#{$kn-spinner-color-n}');
	animation-name: $name;

	.path {
		animation: strio-spinner-dash 1.5s ease-in-out infinite, #{$name} 6s ease-in-out infinite;
	}

	@keyframes #{$name} {
		0%, 20% {
			stroke: rgba($color, 0.6);
		}

		40% {
			stroke: rgba($color, 0.7);
		}

		60% {
			stroke: rgba($color, 0.8);
		}

		80% {
			stroke: rgba($color, 0.9);
		}

		100% {
			stroke: rgba($color, 1);
		}
	}
}
