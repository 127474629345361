@import 'colors';

@function assets-url($path) {
	@return unquote('url("../#{$path}")');
}

kn-datagrid thead[knTableHead] {
	text-align: left;

	& > tr {
		& > th {
			position: relative;
			padding: 0;
			font-weight: 400;
		}

		& > th.contextmenu {
			background-color: rgba($column-active-color, 0.3);
		}
	}

	.header {
		a, span {
			color: $text-secondary-color;
		}

		a.sortable {
			&:hover {
				text-decoration: none;
				color: $text-color;
				text-shadow: 0 0 1px $text-hover-shadow-color;
			}

			&.asc, &.desc {
				color: $text-color;
			}

			&::before {
				content: '';
				background: transparent assets-url('img/arrow-up.svg') center center no-repeat;
				width: 0;
				margin-right: 0;
				display: inline-block;
				height: 20px;
				vertical-align: text-top;
				transform: rotate(90deg);
				opacity: 0;
				transition: 0.25s all ease;
			}

			&:hover::before {
				opacity: 0.5;
				width: 20px;
				margin-right: 3px;
			}

			&.asc::before {
				opacity: 1;
				width: 20px;
				margin-right: 3px;
				transform: rotate(0deg);
			}

			&.desc::before {
				opacity: 1;
				width: 20px;
				margin-right: 3px;
				transform: rotate(180deg);
			}
		}
	}
}
