@mixin strio-audit-display() {
	kn-audit-display {
		white-space: nowrap;
		margin: 10px 5px;

		strong {
			padding: 0.1em 0.4em 0.2em;
			margin: 0 0.2em;
			border-radius: 0.5em;
			font-weight: 400;
		}
	}
}

@mixin strio-audit-display-theme($theme) {
	kn-audit-display {
		color: map-get($theme, text-secondary);

		strong {
			background-color: map-get($theme, em2);
		}
	}
}
