@import '../shared/blend';
@import '../shared/flat-button';

@mixin strio-misc() {
	$misc-img-shadow-color: rgba(0, 0, 0, 0.3);

	kn-sticky.sticked > * {
		z-index: 1000;
		top: 0;
		left: 0;
		right: 0;
	}

	.country-display,
	kn-select.country .option,
	kn-select.country .kn-option-content {
		height: 24px;
		line-height: 24px;

		img {
			width: 24px;
			height: 16px;
			margin: 4px 4px 4px 0;
			vertical-align: bottom;
			box-shadow: 0 1px 3px $misc-img-shadow-color;
		}
	}

	.flat-button {
		@include strio-flat-button();
	}

	.flat-table {
		width: 100%;
		border-collapse: collapse;

		tbody tr {
			height: 55px;
		}

		tbody td {
			transition: 0.3s background-color ease;
			padding: 10px;
		}

		thead td {
			padding: 10px;
			font-weight: 400;
			font-style: italic;
			opacity: 0.7;
		}

		.actions {
			text-align: right;

			button {
				@include strio-flat-button();
			}
		}
	}
}

@mixin strio-misc-theme($theme) {
	kn-input.ownership [kn-prefix] {
		color: map-get($theme, accent);
	}

	.flat-button {
		@include strio-flat-button-theme($theme);
	}

	.flat-table {
		thead td {
			border-bottom: 3px solid blend(map-get($theme, background), map-get($theme, line-disabled));
		}

		tbody {
			td {
				border-bottom: 1px solid blend(map-get($theme, background), map-get($theme, separator));
			}

			tr:hover > td {
				background-color: map-get($theme, hover);
			}
		}

		.actions button {
			@include strio-flat-button-theme($theme);
		}
	}
}
