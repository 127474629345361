@mixin strio-ownership-display() {
	kn-ownership-display > span {
		padding: 4px;
		margin: 0 4px;
		border-radius: 2px;
	}
}

@mixin strio-ownership-display-theme($theme) {
	kn-ownership-display {
		color: map-get($theme, line);

		& > span {
			color: map-get($theme, text);
			background-color: map-get($theme, em);
		}
	}
}
