@import '../shared/grid-menu-button';

@mixin strio-export-button() {
	kn-export-button kn-menu {
		@include strio-grid-menu-button();

		kn-spinner {
			left: 32px;
		}
	}
}

@mixin strio-export-button-theme($theme) {
	kn-export-button kn-menu {
		@include strio-grid-menu-button-theme($theme);
	}
}
