@mixin strio-main() {
	html, body {
		margin: 0;
		height: 100%;
		min-height: 600px;
		font-family: 'Source Sans Pro';
		font-size: 16px;
	}

	input {
		font-family: 'Source Sans Pro';
		font-size: 16px;
	}

	h1, h2, h3, h4, h5, h6 {
		font-family: 'Exo 2';
	}

	h1 {
		font-weight: 300;
		font-size: 48px;
		padding-bottom: 10px;
	}

	a {
		text-decoration: none;
	}

	a:hover, a:focus {
		text-decoration: underline;
	}

	kn-app-main {
		display: block;
	}

	[knBackgroundFocusable] {
		outline: none;
	}
}

@mixin strio-main-theme($theme) {
	html, body {
		background-color: map-get($theme, page-background);
		color: map-get($theme, text);
	}

	a {
		color: map-get($theme, link);
	}
}
