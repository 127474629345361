@import '../shared/raised-button';

@mixin strio-form-button() {
	button.form-button, .form-input button {
		@include strio-raised-button();
	}
}

@mixin strio-form-button-theme($theme) {
	button.form-button, .form-input button {
		@include strio-raised-button-theme($theme);
	}
}
