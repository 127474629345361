@mixin strio-interpolated-native-field-theme($name, $theme) {
	kn-#{$name} {
		#{$name} {
			color: map-get($theme, foreground);
		}

		&.disabled #{$name} {
			color: map-get($theme, text-disabled);
		}

		#{$name}::placeholder {
			color: map-get($theme, placeholder);
		}
	}
}
