@mixin strio-interpolated-loader-bar($bar-selector) {
	#{$bar-selector} {
		position: relative;

		&::before, &::after {
			content: '';
			position: absolute;
			top: 100%;
			height: 5px;
			z-index: 3;
		}

		&::before {
			left: 0;
			width: 100%;
			opacity: 0;
			transition: 0.3s opacity ease;
		}

		&::after {
			left: 0%;
			right: 100%;
			opacity: 0;
		}
	}

	.loading {
		#{$bar-selector} {
			&::before, &::after {
				opacity: 1;
			}

			&::after {
				animation: indeterminate 1s linear infinite;
			}
		}
	}
}

@mixin strio-interpolated-loader-bar-theme($bar-selector, $theme) {
	#{$bar-selector} {
		&::before {
			background-color: rgba(map-get($theme, primary), 0.3); // sass-lint:disable-line no-color-literals
		}

		&::after {
			background-color: map-get($theme, primary);
		}
	}
}
