// sass-lint:disable no-color-literals

svg {
	transform: translateZ(0);
}

.content.center, .form-buttons.center {
	text-align: center;
}

.option, .kn-option-content {
	span.note {
		opacity: 0.5;

		&:not(:empty)::before {
			content: ' — ';
			margin-left: 2px;
		}
	}

	span.color {
		display: inline-block;
		width: 24px;
		height: 20px;
		margin-right: 4px;
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
	}
}

.form-message {
	margin: 50px auto;
	max-width: 1200px;
	font-style: italic;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #c04060;

	i {
		margin-right: 10px;
		font-size: 32px;
	}
}

kn-view-template-selector {
	display: flex;
	margin: 10px 0 20px 0;

	kn-input, kn-select {
		flex: 1;
	}
}

.row.highlight {
	&:hover {
		background-color: rgba(0, 0, 0, 0.1);
	}
}
