@import '../core/assets-url';

@mixin strio-header-liner-user-menu() {
	header.liner .user-menu {
		kn-menu-activator {
			min-width: 120px;
			flex-direction: row-reverse;
			align-items: center;

			img {
				width: 48px;
				height: 48px;
				margin-left: 10px;
				background-image: assets-url('img/noavatar.svg');
			}

			kn-menu-content {
				right: 0;
			}
		}

		kn-menu-content {
			right: 0;
			left: initial;

			.separator::before {
				content: '';
				display: block;
				height: 1px;
				width: calc(100% - 20px);
				margin: 10px;
			}

			.separator:first-child, .separator:last-child {
				display: none;
			}
		}
	}

	@at-root kn-attached-portal .user-menu-content {
		right: 0;
		left: initial;

		.separator::before {
			content: '';
			display: block;
			height: 1px;
			width: calc(100% - 20px);
			margin: 10px;
		}

		.separator:first-child, .separator:last-child {
			display: none;
		}
	}
}

@mixin strio-header-liner-user-menu-theme($theme) {
	header.liner .user-menu kn-menu-content .separator::before {
		background-color: rgba(map-get($theme, foreground), 0.3); // sass-lint:disable-line no-color-literals
	}

	@at-root kn-attached-portal .user-menu-content .separator::before {
		background-color: rgba(map-get($theme, foreground), 0.3); // sass-lint:disable-line no-color-literals
	}
}
