@mixin strio-database-manage() {
	app-database-manage {
		kn-tabs {
			position: relative;
			padding-top: 50px;
			margin: 0 0 20px 0;

			.tab-group {
				position: absolute;
				top: 0;
				width: 100%;
				margin: 0;
				padding: 7px 0;
				font-size: 18px;
				font-weight: 400;
			}

			a {
				i.material-icons {
					font-size: 72px;
				}

				strong {
					font-weight: 400;
				}
			}

			kn-tab {
				position: relative;
				padding: 30px;
				justify-content: center;
				text-align: center;

				.actions {
					margin: 20px auto 0 auto;
				}

				p.note {
					margin-top: 0;
					font-style: italic;
					opacity: 0.5;
				}

				.select-database {
					margin: 20px auto;
					padding: 20px;
					width: 400px;
				}

				&::after {
					content: '';
					display: block;
					position: absolute;
					width: calc(100% - 2px);
					height: calc(100% - 2px);
					left: 0;
					top: 0;
					opacity: 0;
					z-index: 10;
					pointer-events: none;
					transition: 0.3s all ease;
				}
				&.kn-drag-over::after {
					opacity: 1;
					width: calc(100% - 20px);
					height: calc(100% - 20px);
					left: 10px;
					top: 10px;
				}
			}
		}
	}
}

@mixin strio-database-manage-theme($theme) {
	$database-manage-drag-over-background-color: rgba(255, 240, 216, 0.3);
	$database-manage-drag-over-border-color: rgb(255, 152, 0);

	app-database-manage {
		kn-tabs {
			.tab-group {
				border-bottom: 1px solid map-get($theme, caption-divider);
				color: map-get($theme, text-secondary);
			}

			a {
				i.material-icons {
					color: map-get($theme, icon-disabled);
				}
			}

			li.active a, a:hover, a:focus {
				i.material-icons {
					color: map-get($theme, icon);
				}
			}

			kn-tab {
				.select-database {
					background-color: map-get($theme, tone);
					border: 1px solid map-get($theme, divider);
				}

				&.kn-drag-over::after {
					background-color: $database-manage-drag-over-background-color;
					border: 1px dashed $database-manage-drag-over-border-color;
				}
			}
		}
	}
}
