@import '../shared/field';
@import '../shared/native-field';

@mixin strio-form-slots-table() {
	.form-slots-table {
		width: 100%;
		table-layout: fixed;
		margin-top: 2em;
		border-collapse: collapse;

		th {
			padding: 5px;
			font-weight: 400;
			position: relative;

			&::before {
				content: '';
				position: absolute;
				display: block;
				width: calc(100% - 22px);
				height: 100%;
				border: 1px solid transparent;
				top: 0;
				left: 11px;
			}

			&.disabled::before {
				background-color: transparent;
			}
		}

		td {
			padding: 10px 0;
		}

		caption {
			position: relative;
			padding: 10px;
			margin-bottom: 10px;
			z-index: 1;
		}
	}
}

@mixin strio-form-slots-table-theme($theme) {
	.form-slots-table {
		th {
			color: map-get($theme, text-secondary);

			&::before {
				background-color: map-get($theme, em);
			}

			&.disabled::before {
				border: 1px solid map-get($theme, em);
			}
		}

		caption {
			border-bottom: 1px solid map-get($theme, caption-divider);
			color: map-get($theme, text-secondary);
		}
	}
}
