@import '../shared/grid-menu';

@mixin strio-grid-preset() {
	kn-grid-preset {
		kn-menu {
			@include strio-grid-menu();

			width: 300px;

			kn-menu-activator {
				display: block;
				opacity: 1;
			}
		}

		kn-menu-activator > div {
			padding: 0;
			margin: 0;
			line-height: 24px;
			display: flex;
			align-items: center;

			&[hidden] {
				display: none;
			}

			i {
				padding: 0;
			}

			span, a {
				display: inline-block;
				line-height: 24px;
				height: 24px;
				padding: 5px;
				transition: color 0.5s ease;

				&:hover, &:focus {
					opacity: 1;
				}
			}

			span {
				font-style: italic;
				flex: 1;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;

				&.template-name {
					font-style: normal;

					>i.ownerUid {
						font-size: 80%;
						font-style: italic;
					}

					>i.material-icons {
						font-size: 125%;
						vertical-align: middle;
					}
				}
			}

			input {
				height: 34px;
				padding: 0 5px;
				width: calc(100% - 10px);
				min-width: 0;
				border: none;
				background-color: transparent;
				font-family: Source Sans Pro;
				font-size: 16px;
				outline: none;
			}

			a {
				opacity: 0.7;
			}
		}

		kn-menu-content {
			kn-select {
				width: 100%;
			}

			ul {
				list-style-type: none;
				padding: 0;
				margin: 0;
			}

			li:not(.user-select) {
				padding: 0;
				margin: 0;
				display: flex;
				justify-content: space-between;

				span, a {
					cursor: pointer;
					display: inline-block;
					line-height: 24px;
					height: 24px;
					padding: 5px;
				}

				span {
					flex: 1;
					text-overflow: ellipsis;
					white-space: nowrap;

					>i.ownerUid {
						font-size: 80%;
						font-style: italic;
					}

					>i.material-icons {
						font-size: 125%;
						vertical-align: middle;
					}
				}
			}
		}
	}

	@at-root kn-attached-portal .kn-menu-content.kn-preset-selector-content {
		kn-select {
			width: 100%;
		}

		ul {
			list-style-type: none;
			padding: 0;
			margin: 0;
		}

		li:not(.user-select) {
			padding: 0;
			margin: 0;
			display: flex;
			justify-content: space-between;

			span, a {
				cursor: pointer;
				display: inline-block;
				line-height: 24px;
				height: 24px;
				padding: 5px;
			}

			span {
				flex: 1;
				text-overflow: ellipsis;
				white-space: nowrap;

				>i.ownerUid {
					font-size: 80%;
					font-style: italic;
				}

				>i.material-icons {
					font-size: 125%;
					vertical-align: middle;
				}
			}
		}
	}
}

@mixin strio-grid-preset-theme($theme) {
	kn-grid-preset {
		kn-menu {
			@include strio-grid-menu-theme($theme);
		}

		kn-menu-activator > div {
			span, a {
				transition: color 0.5s ease;

				&:hover, &:focus {
					color: map-get($theme, background);
					background-color: map-get($theme, grid-menu-activated-hover);
				}
			}

			span {
				color: rgba(map-get($theme, background), 0.5); // sass-lint:disable-line no-color-literals

				&.template-name {
					color: map-get($theme, grid-active-selection);
				}
			}

			input {
				color: map-get($theme, background);

				&::placeholder {
					color: rgba(map-get($theme, background), 0.5); // sass-lint:disable-line no-color-literals
				}
			}

			a {
				color: rgba(map-get($theme, background), 0.5); // sass-lint:disable-line no-color-literals
			}
		}

		kn-menu-content {
			li:not(.user-select) {
				span:hover, a:hover {
					color: map-get($theme, background);
					background-color: map-get($theme, grid-menu-activated-hover);
				}

				span {
					color: rgba(map-get($theme, background), 0.7); // sass-lint:disable-line no-color-literals
				}

				a {
					color: rgba(map-get($theme, background), 0.5); // sass-lint:disable-line no-color-literals
				}
			}

			kn-select {
				color: map-get($theme, text);

				kn-options-container, kn-option {
					color: map-get($theme, text);
				}
				background-color: map-get($theme, background);
			}
		}
	}

	@at-root kn-attached-portal .kn-menu-content.kn-preset-selector-content {
		li:not(.user-select) {
			span:hover, a:hover {
				color: map-get($theme, background);
				background-color: map-get($theme, grid-menu-activated-hover);
			}

			span {
				color: rgba(map-get($theme, background), 0.7); // sass-lint:disable-line no-color-literals
			}

			a {
				color: rgba(map-get($theme, background), 0.5); // sass-lint:disable-line no-color-literals
			}
		}

		kn-select {
			color: map-get($theme, text);

			kn-options-container, kn-option {
				color: map-get($theme, text);
			}
			background-color: map-get($theme, background);
		}
	}
}
