@mixin strio-interpolated-field($name) {
	kn-#{$name} {
		label, [kn-hint] {
			font-size: 14px;
		}

		[kn-prefix], [kn-suffix] {
			display: flex;
		}

		.kn-#{$name}-container {
			border-bottom-width: 2px;
			border-bottom-style: solid;
			border-radius: 2px;
			transition: 150ms all ease;
		}

		.kn-#{$name}-underline {
			&::before, &::after {
				content: '';
				position: absolute;
				top: -3px;
				height: 3px;
				display: block;
				z-index: 101;
			}

			&::before {
				left: 50%;
				width: 0;
				opacity: 0;
				transition: 0.3s all ease, 0.5s background-color ease;
			}

			&::after {
				transition: 0.3s all ease, 0.5s background-color ease;
			}
		}

		&.disabled, &.readonly {
			.kn-#{$name}-container {
				border-bottom-style: dotted;
			}
		}

		&.focused .kn-#{$name}-underline {
			&::before {
				left: 0%;
				width: 100%;
				opacity: 1;
			}
			&::before, &::after {
				z-index: 111;
			}
		}

		&.ng-touched.ng-invalid .kn-#{$name}-underline::before {
			left: 0%;
			width: 100%;
			opacity: 1;
		}

		&.ng-touched.ng-pending .kn-#{$name}-underline,
		&.ng-touched.pending .kn-#{$name}-underline,
		&.focused.ng-pending .kn-#{$name}-underline,
		&.focused.pending .kn-#{$name}-underline,
		&.focused.loading .kn-#{$name}-underline {
			&::before, &::after {
				top: -4px;
				height: 5px;
			}

			&::after {
				animation: indeterminate 1s linear infinite;
			}
		}
	}
}

@mixin strio-interpolated-field-theme($name, $theme) {
	kn-#{$name} {
		label, [kn-hint] {
			color: map-get($theme, text-secondary);
		}

		label .required {
			color: map-get($theme, warn);
		}

		.kn-#{$name}-container {
			background-color: map-get($theme, undertone);
			border-bottom-color: map-get($theme, input-divider);
		}

		&:not(.disabled):not(.focused) .kn-#{$name}-container:hover {
			border-color: map-get($theme, input-divider-hover);
		}

		&.focused .kn-#{$name}-container {
			border-color: transparent;
		}

		.kn-#{$name}-underline {
			&::before, &::after {
				background-color: map-get($theme, primary);
			}
		}

		&.disabled {
			color: map-get($theme, text-disabled);
		}

		&.ng-touched.ng-invalid .kn-#{$name}-underline::before {
			background-color: map-get($theme, warn);
		}

		&.ng-touched.ng-invalid.loading .kn-#{$name}-underline {
			&::before {
				background-color: mix(map-get($theme, warn), map-get($theme, background), 30%);
			}

			&::after {
				background-color: map-get($theme, warn);
			}
		}

		&.ng-touched.ng-pending .kn-#{$name}-underline::before,
		&.ng-touched.pending .kn-#{$name}-underline::before,
		&.focused.ng-pending .kn-#{$name}-underline::before,
		&.focused.pending .kn-#{$name}-underline::before,
		&.focused.loading .kn-#{$name}-underline::before {
			background-color: mix(map-get($theme, primary), map-get($theme, background), 30%);
		}
	}
}
